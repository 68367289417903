import React, { FC, useEffect, useState } from 'react';
import classes from './WinnersDialog.module.scss';
import inviteClasses from './InviteDialog.module.scss';
import Button from '@envclient/envcore/src/components/Button';
import Modal from '@envclient/envcore/src/components/Modal';
import { useDispatch } from 'react-redux';
import WsConnection from '@envclient/envcore/src/service/WsConnection';
import { consumeInvitation } from '@envclient/envcore/src/reducers/invitationReducer';
import { GameConfigDTO, InviteMessageDTO } from '@envclient/envcore/src/types/core';
import { useInterval } from 'react-use';
import { useAppSelector } from '../reducers/store';
import { isSupported } from '../utils/game';

const InviteDialog: FC<Props> = ({ invite, onClose }) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const interval = 1000;
  const inviteTimeout = 30;

  const meUser = useAppSelector((state) => state.user);
  const gameList = useAppSelector((state) => state.gameList);

  const gameRoom = gameList.find((g) => g.room.roomId === invite.room.roomId);

  const handleAccept = () => {
    // TODO -invite shouldnt even appear if not supported
    const roomGameConfig = window.gameConfigs.find((g) => g.type === gameRoom?.gameType) as GameConfigDTO;
    if (!isSupported(roomGameConfig, window.appConfig.version)) return;
    WsConnection.send({ type: 'JOIN', room: invite.room });
    dispatch(consumeInvitation(invite));
    onClose();
  };

  const handleDeny = () => {
    dispatch(consumeInvitation(invite));
    onClose();
  };

  useInterval(
    () => {
      setCount(count + 1);
    },
    count < inviteTimeout ? interval : null
  );

  useEffect(() => {
    if (count === inviteTimeout) {
      dispatch(consumeInvitation(invite));
      onClose();
    }
  }, [count, invite, dispatch, onClose]);

  useEffect(() => {
    if (count === 5 && meUser?.type === 'bot') {
      WsConnection.send({ type: 'JOIN', room: invite.room });
      dispatch(consumeInvitation(invite));
      onClose();
    }
  }, [count, invite, dispatch, onClose, meUser]);

  return (
    <Modal>
      <div className={classes.mainCont}>
        <div className={classes.bg}></div>
        <div className={classes.panel}>
          <div className={classes.header}>
            <div className={classes.title}>Invite</div>
          </div>
          <div className={classes.body + ' ' + inviteClasses.body}>
            <p className={classes.winners}>
              {invite.fromUser.displayName} has invited you to {invite.room.roomName}.
              {gameRoom && (
                <>
                  <br /> Speed: {gameRoom?.delay / 1000} sec / Max. players: {gameRoom?.maxPlayer}
                </>
              )}
              <br /> Do you accept?
              <br />
              Invite expires in {inviteTimeout - count} seconds...
            </p>
          </div>
          <div className={classes.btnBar}>
            <Button onClick={handleDeny}>No thanks</Button>
            <Button onClick={handleAccept}>OK</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

interface Props {
  invite: InviteMessageDTO;
  onClose: () => void;
}
export default InviteDialog;
