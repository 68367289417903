import { Reducer } from 'redux';
import { RoomDTO } from '../types/core';

const CURRENT_ROOM = 'CURRENT_ROOM';

const initialState = null;

export const setCurrentRoom = (room: RoomDTO) => ({ type: CURRENT_ROOM, payload: room });

const currentRoomReducer: Reducer<RoomDTO | null> = (state = initialState, action) => {
  if (action.type === CURRENT_ROOM) {
    return action.payload;
  }
  return state;
};

export default currentRoomReducer;
