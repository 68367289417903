import React, { FC } from 'react';
import cityImage from '../../assets/guide/city.png';
import road from '../../assets/guide/road.png';
import cloister from '../../assets/guide/cloister.png';
import field from '../../assets/guide/field.png';
import cathedral from '../../assets/guide/cathedral.png';
import inn from '../../assets/guide/inn.png';
import classes from './Guide.module.scss';
import { ccsonTileCount } from '../../config/tileMapConfig';
import GuideTileItem from './GuideTileItem';

const CarcassonneGuide: FC = () => {
  return (
    <>
      <p>
        Carcassonne is German-style board game designed by Klaus-Jürgen Wrede and originally published by
        <a href="http://www.hans-im-glueck.de/" target="blank">
          Hans im Glück Verlag.
        </a>
      </p>

      <p>
        You can read more about the game rules here:
        <a href="./static/public/rules/carcassonne_base_rules.pdf" target="blank">
          Carcassonne Base game,
        </a>
        <a href="./static/public/rules/carcassonne_exp1_rules.pdf" target="blank">
          Exp1 - Inns and Cathedrals,
        </a>
        <a href="./static/public/rules/carcassonne_exp2_rules.pdf" target="blank">
          Exp2 - Traders and Builders
        </a>
      </p>

      <h3>Scoring</h3>

      <p>
        <span>Cities</span> - 2 points / tile and pennant when completed, otherwise 1 point / tile and pennant.
      </p>
      <img src={cityImage} />
      <p>
        <span>Roads</span> - 1 points / tile when completed
      </p>
      <img src={road} />
      <p>
        <span>Cloisters</span> - 1 point for each neighbouring tile, 9 points when completed
      </p>
      <img src={cloister} />
      <p>
        <span>Fields</span> - 3 points for each completed city on the field - only at the end of game
      </p>
      <img src={field} />
      <p>
        <span>Cathedral</span> - a city with a cathedral scores 3 points / tile if completed otherwise none
      </p>
      <img src={cathedral} />
      <p>
        <span>Inn</span> - a road with an inn next to it scores 2 points / tile if completed otherwise none
      </p>
      <img src={inn} />

      <h3>Mini tiles</h3>
      <div className="miniTiles tileList"></div>
      <div className={classes.tileList}>
        {ccsonTileCount.mini.map((tc) => (
          <GuideTileItem key={tc} tc={tc} family={'ccson'} />
        ))}
      </div>

      <h3>Base tiles</h3>
      <div className={classes.tileList}>
        {ccsonTileCount.base.map((tc) => (
          <GuideTileItem key={tc} tc={tc} family={'ccson'} />
        ))}
      </div>

      <h3>First expansion tiles</h3>
      <div className={classes.tileList}>
        {ccsonTileCount.exp1.map((tc) => (
          <GuideTileItem key={tc} tc={tc} family={'ccson'} />
        ))}
      </div>

      <h3>Second expansion tiles</h3>
      <div className={classes.tileList}>
        {ccsonTileCount.exp2.map((tc) => (
          <GuideTileItem key={tc} tc={tc} family={'ccson'} />
        ))}
      </div>
    </>
  );
};

export default CarcassonneGuide;
