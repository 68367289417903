import { RootState, useAppSelector } from '../reducers/store';
import { PlayerDTO } from '../types/core';

const useMyPlayer = () => {
  return useAppSelector((state: RootState) => {
    if (state.game.env && state.game.env.players && state.user) {
      return state.game.env.players.find((p) => p.id === state.user?.userId);
    }
    return null;
  }) as PlayerDTO;
};

export { useMyPlayer };
