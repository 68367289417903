import React, { FC } from 'react';
import classes from './Alert.module.scss';
import Button from '@envclient/envcore/src/components/Button';
import Modal from '@envclient/envcore/src/components/Modal';

const Alert: FC<Props> = ({ title, message, onClose }) => {
  return (
    <Modal>
      <div className={classes.mainCont}>
        <div className={classes.bg}></div>
        <div className={classes.panel}>
          <div className={classes.header}>
            <div className={classes.title}>{title}</div>
          </div>
          <div className={classes.body}>{message}</div>
          <div className={classes.btnBar}>
            <Button onClick={onClose}>OK</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

interface Props {
  title: string;
  message: string;
  onClose: () => void;
}
export default Alert;
