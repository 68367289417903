import React, { FC } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import HomeView from '@envclient/envcore/src/components/HomeView';
import PageCover from './ui/PageCover';
import PrivacyPolicy from './ui//PrivacyPolicy';
import GameCont from '@envclient/envcore/src/components/GameCont';
import GameView from '@envclient/envcore/src/components/GameView';
import Guide from './ui/guide/Guide';
import CreateGame from './ui/CreateGame';
import GameCanvas from './envs/ccson/GameCanvas';
import CcsonPlayerItem from './envs/ccson/CcsonPlayerItem';
import { PlayerItemProps } from '@envclient/envcore/src/components/PlayerList';
import './config/appConfig';
import KoenigsburgGameCanvas from './envs/koenigsburg/KGameCanvas';
import { useSelector } from 'react-redux';
import { RootState } from '@envclient/envcore/src/reducers/store';

const DynamicGameView: FC = () => {
  const env = useSelector((state: RootState) => state.game.env);

  switch (env?.gameConfig.family) {
    case 'ccson':
    case 'sea':
    case 'castle':
      return <GameView GameCanvas={GameCanvas} PlayerItem={CcsonPlayerItem as FC<PlayerItemProps>} />;
    case 'koenigsburg':
      return <GameView GameCanvas={KoenigsburgGameCanvas} PlayerItem={CcsonPlayerItem as FC<PlayerItemProps>} />;
    default:
      return null;
  }
};

const App: FC = () => {
  const location = useLocation();

  const title =
    'Seven Castles ' +
    new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, minimumFractionDigits: 1 }).format(
      window.appConfig.version
    );

  return (
    <TransitionGroup>
      <CSSTransition key={location.pathname} classNames="fade" timeout={1000}>
        <Switch location={location}>
          <Route path="/game">
            <GameCont GameView={DynamicGameView} Guide={Guide} CreateGame={CreateGame} />
          </Route>
          <Route path="/privacypolicy">
            <PrivacyPolicy />
          </Route>
          <Route path="/">
            <HomeView PageCover={PageCover} title={title} />
          </Route>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default App;
