import axios from 'axios';
import { loadToken } from '../login/localStorage';
import { history } from '../reducers/store';

// eslint-disable-next-line no-undef
const apiUrl = window.runtimeConfig.REACT_APP_API_URL;

const axiosClient = axios.create({
  baseURL: apiUrl,
  headers: { Accept: 'application/json;charset=UTF-8' },
  withCredentials: true
});

axiosClient.interceptors.request.use(function (config) {
  const accessToken = loadToken();
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log('Authentication error', error.response);
      // redirect to login
      history.push('/login');
    }
    console.log('Service error: ', error.response);
    return Promise.reject(error);
  }
);

export default axiosClient;
