import axiosClient from './axiosClient';
import { AxiosRequestConfig } from 'axios';

const endpoint = 'payment';

// optional fields only present for Android or IOS
export interface InsertPayment {
  platform: string;
  userId: number;
  credits: string;
  itemId: string;
  paymentId: string;
  receipt: string;
  signature?: string;
}

const insertPayment = (payload: InsertPayment) => {
  const requestConfig: AxiosRequestConfig = {
    url: `${endpoint}/mobilePayment`,
    method: 'post',
    data: payload
  };
  return axiosClient
    .request(requestConfig)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export { insertPayment };
