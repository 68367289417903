import { observeStore } from '../utils/redux';
import { AppStatus, setAppStatus } from '../reducers/appStatusReducer';
import { setUser } from '../reducers/userReducer';
import { setGameList } from '../reducers/gameListReducer';
import { addInvitation } from '../reducers/invitationReducer';
import { updateGame, joinGame, leaveGame, setGameUserList, updateEnv, gameEvent } from '../reducers/gameActions';
import { setLobbyUserList, joinLobby, leaveLobby, lobbyChatMessage, lobbyChatHistory } from '../reducers/lobbyReducer';
import store, { history, RootState } from '../reducers/store';
import { RouterState } from 'connected-react-router';
import { loadToken } from '../login/localStorage';
import {
  ChatHistoryMessageDTO,
  ChatMessageDTO,
  ClientMessageUnion,
  GameEventMessageDTO,
  GameListMessageDTO,
  InviteMessageDTO,
  JoinMessageDTO,
  LeaveMessageDTO,
  ServerMessage,
  SetUserMessageDTO,
  UpdateEnvMessageDTO,
  UpdateGameMessageDTO,
  UserListMessageDTO
} from '../types/core';
import { setCurrentRoom } from '../reducers/currentRoomReducer';
import { gameChatHistory, gameChatMessage } from '../reducers/gameChatReducer';

class WsConnection {
  connecting: boolean;
  connected: boolean;
  ws: WebSocket | null;
  url: string;
  hbTimeout: NodeJS.Timeout | null;
  hbDelay = 30000;

  constructor() {
    observeStore(store, (state: RootState) => state.router, this.handleRouteChange.bind(this));
    this.connected = this.connecting = false;
    this.ws = null;
    this.url = '';
    this.hbTimeout = null;
  }

  handleRouteChange(router: RouterState, oldRouter: RouterState) {
    if (oldRouter?.location.pathname.startsWith('/game') && !router.location.pathname.startsWith('/game')) {
      if (this.ws) {
        console.log('wsDisconnect caused by route change');
        this.disconnect();
      }
    }
  }

  restartHb() {
    if (this.hbTimeout) clearTimeout(this.hbTimeout);
    if (this.ws && this.ws.readyState == this.ws.OPEN) {
      this.send({ type: 'HEARTBEAT' });
    }
    this.hbTimeout = setTimeout(this.restartHb.bind(this), this.hbDelay);
  }

  stopHb() {
    if (this.hbTimeout) clearTimeout(this.hbTimeout);
    this.hbTimeout = null;
  }

  connect() {
    if (this.connected || this.connecting) return;
    // eslint-disable-next-line no-undef
    this.url = window.runtimeConfig.REACT_APP_WS_URL as string;
    this.url += '?scToken=' + loadToken();
    this.ws = new WebSocket(this.url);
    this.connecting = true;

    this.ws.onopen = this.onConnect.bind(this);
    this.ws.onerror = this.onError.bind(this);
    this.ws.onclose = this.onDisconnect.bind(this);
    this.ws.onmessage = this.onMessage.bind(this);
  }

  disconnect() {
    this.ws?.close();
  }

  // socket events

  onError(e: Event) {
    console.log('Websocket error', e);
    // TODO handle connection errors
    //connectBtn.text('Connection error.')
  }

  onConnect() {
    console.log('onConnect');
    this.connected = true;
    this.connecting = false;
    store.dispatch(setAppStatus(AppStatus.LOBBY));
    history.push('/game');

    this.restartHb();
  }

  onDisconnect() {
    console.log('onDisconnect');
    this.connected = false;
    this.connecting = false;
    store.dispatch(setAppStatus(AppStatus.DISCONNECTED));
    this.stopHb();
    if (store.getState().router.location.pathname.startsWith('/game')) {
      history.push('/');
    }
  }

  // ************** server -> client

  onMessage(e: MessageEvent) {
    const msg: ServerMessage = JSON.parse(e.data);
    const { type } = msg;
    switch (type) {
      case 'SET_USER':
        this.onSetUser(msg as SetUserMessageDTO);
        break;
      case 'JOIN':
        this.onJoin(msg as JoinMessageDTO);
        break;
      case 'LEAVE':
        this.onLeave(msg as LeaveMessageDTO);
        break;
      case 'SET_GAME_LIST':
        this.onSetGameList(msg as GameListMessageDTO);
        break;
      case 'INVITE':
        this.onInvite(msg as InviteMessageDTO);
        break;
      case 'UPDATE_GAME':
        this.onUpdateGame(msg as UpdateGameMessageDTO);
        break;
      case 'UPDATE_ENV':
        this.onUpdateEnv(msg as UpdateEnvMessageDTO);
        break;
      case 'USER_LIST':
        this.onUserList(msg as UserListMessageDTO);
        break;
      case 'GAME_EVENT':
        this.onGameEvent(msg as GameEventMessageDTO);
        break;
      case 'CHAT':
        this.onChat(msg as ChatMessageDTO);
        break;
      case 'CHAT_HISTORY':
        this.onChatHistory(msg as ChatHistoryMessageDTO);
        break;
      /*
    case 'message'	:	this.serverMessage(message);	break;
    */
      default:
        break;
    }
  }

  onSetUser(data: SetUserMessageDTO) {
    console.log('setUser', data);
    store.dispatch(setUser(data.user));
  }

  onSetGameList(data: GameListMessageDTO) {
    console.log('setGameList', data);
    store.dispatch(setGameList(data.gameList));
  }

  onJoin(data: JoinMessageDTO) {
    console.log('join', data);

    // join room
    if (data.room.roomId === 0) {
      store.dispatch(joinLobby(data.user));
    } else {
      store.dispatch(joinGame(data.user));
    }

    // set appStatus and currentRoom
    if (data.user.userId === store.getState().user?.userId) {
      // set current room
      store.dispatch(setCurrentRoom(data.room));
      // go back to lobby
      if (data.room.roomId === 0) {
        store.dispatch(setAppStatus(AppStatus.LOBBY));
      }
      // go to game
      else {
        store.dispatch(setAppStatus(AppStatus.GAME));
      }
    }
  }

  onLeave(data: LeaveMessageDTO) {
    if (!data || !data.user) return;
    console.log('leave', data.user.userId, data);
    if (data.room.roomId === 0) store.dispatch(leaveLobby(data.user));
    else store.dispatch(leaveGame(data.user));
  }

  onInvite(data: InviteMessageDTO) {
    console.log('invite', data.room.roomId, data.user.displayName);
    store.dispatch(addInvitation(data));
  }

  onUserList(data: UserListMessageDTO) {
    console.log('userList', data);
    if (data.room.roomId === 0) store.dispatch(setLobbyUserList(data.users));
    else store.dispatch(setGameUserList(data.users));
  }

  onUpdateGame(data: UpdateGameMessageDTO) {
    console.log('onUpdateGame', data);
    store.dispatch(updateGame(data.game));
  }

  onUpdateEnv(data: UpdateEnvMessageDTO) {
    console.log('onUpdateEnv', data);
    store.dispatch(updateEnv(data.env));
  }

  onGameEvent(data: GameEventMessageDTO) {
    console.log('onGameEvent', data);
    store.dispatch(gameEvent(data.event));
  }

  onChat(data: ChatMessageDTO) {
    console.log('onChat', data);
    if (data.roomId === 0) {
      store.dispatch(lobbyChatMessage(data.chatEntry));
    } else {
      store.dispatch(gameChatMessage(data.chatEntry));
    }
  }

  onChatHistory(data: ChatHistoryMessageDTO) {
    console.log('onChatHistory', data);
    if (data.roomId === 0) {
      store.dispatch(lobbyChatHistory(data.history));
    } else {
      store.dispatch(gameChatHistory(data.history));
    }
  }

  // ************ client -> server

  send(lastMessage: ClientMessageUnion) {
    if (!this.ws) return;
    console.log('ws.send', lastMessage);
    const msg = JSON.stringify(lastMessage);
    this.ws.send(msg);
  }
}

export default new WsConnection();
