import React, { FC } from 'react';
import classes from './CircleLoader.module.scss';

const CircleLoader: FC<Props> = ({ size = 20 }) => {
  const loaderStyle = { width: size, height: size };
  return <div className={classes.loader} style={loaderStyle}></div>;
};

interface Props {
  size: number;
}

export default CircleLoader;
