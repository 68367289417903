import { combineReducers } from 'redux';
import gameUserlistReducer from './gameUserlistReducer';
import gameDataReducer from './gameDataReducer';
import gameEnvReducer from './gameEnvReducer';
import gameEventsReducer from './gameEventsReducer';
import gameChatReducer from './gameChatReducer';

export default combineReducers({
  game: gameDataReducer,
  env: gameEnvReducer,
  userList: gameUserlistReducer,
  events: gameEventsReducer,
  chatHistory: gameChatReducer
});
