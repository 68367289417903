import { Reducer } from 'redux';
import { ActionDTO, EnvDTO, GameConfigDTO, PlayerDTO } from '../types/core';
import { AppStatus } from './appStatusReducer';
import { UPDATE_ENV } from './gameActions';

const initialState = null;

const gameEnvReducer: Reducer<EnvDTO<GameConfigDTO, PlayerDTO, ActionDTO> | null> = (state = initialState, action) => {
  if (action.type === UPDATE_ENV) {
    return action.env;
  } else if (action.payload === AppStatus.LOBBY) {
    return initialState;
  }
  return state;
};

export default gameEnvReducer;
