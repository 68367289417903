import { Reducer } from 'redux';
import { APP_STATUS } from './actions';

export enum AppStatus {
  LOBBY = 'LOBBY',
  GAME = 'GAME',
  DISCONNECTED = 'DISCONNECTED'
}

const initialState: AppStatus = AppStatus.DISCONNECTED;

export const setAppStatus = (payload: AppStatus) => ({ type: APP_STATUS, payload });

const appStatusReducer: Reducer<AppStatus> = (state = initialState, action): AppStatus => {
  if (action.type === APP_STATUS) {
    return action.payload;
  }
  return state;
};

export default appStatusReducer;
