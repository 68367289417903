import { Reducer } from 'redux';
import { FIRST_CONNECT } from './actions';

const initialState = true;

export const setFirstConnect = (payload: boolean) => ({ type: FIRST_CONNECT, payload });

const firstConnectReducer: Reducer<boolean> = (state = initialState, action) => {
  if (action.type === FIRST_CONNECT) {
    return action.payload;
  }
  return state;
};

export default firstConnectReducer;
