import React, { FC } from 'react';
import classes from './WinnersDialog.module.scss';
import Button from '@envclient/envcore/src/components/Button';
import Modal from './Modal';
import { PlayerDTO } from '@envclient/envcore/src/types/core';

const WinnersDialog: FC<Props> = ({ winners, onClose }) => {
  return (
    <Modal>
      <div className={classes.mainCont}>
        <div className={classes.bg}></div>
        <div className={classes.panel}>
          <div className={classes.header}>
            <div className={classes.title}>The winners are:</div>
          </div>
          <div className={classes.body}>
            {winners.map((p) => (
              <div className={classes.playerItem} key={p.id}>
                {p.displayName} : {p.score} points
              </div>
            ))}
          </div>
          <div className={classes.btnBar}>
            <Button onClick={() => onClose()}>OK</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

interface Props {
  winners: PlayerDTO[];
  onClose: () => void;
}
export default WinnersDialog;
