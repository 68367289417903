import Button from '@envclient/envcore/src/components/Button';
import { history } from '@envclient/envcore/src/reducers/store';
import React, { FC } from 'react';
import { FaTimes } from 'react-icons/fa';
import classes from './PrivacyPolicy.module.scss';

const PrivacyPolicy: FC = () => {
  const navigateBack = () => {
    history.push('/');
  };

  return (
    <div className={classes.mainCont}>
      <div className={classes.header}>
        <div className={classes.title}>Seven Castles Privacy Policy</div>
        <Button onClick={navigateBack} secondary>
          <FaTimes />
        </Button>
      </div>

      <div className={classes.body}>
        <p>
          The <span className={classes.highlight}>Seven Castles </span> application uses Google or Facebook or Apple
          sign in for authentication.
        </p>
        <p>The collected used data is only used for authentication.</p>
        <p>
          When a user signs in with Facebook their Facebook name and Facebook id associated with the Seven Castles App
          is stored in our database. No other data is stored in our database.
        </p>
        <p>When a user signs in with Google their Gmail adress and username is stored in our database.</p>
        <p>
          When a user signs in with Apple their Apple email adress and potentially the username is stored in our
          database.
        </p>
        <p>
          The collected used data is <span className={classes.highlight}>only used for authentication.</span>
        </p>
        <p>The information received is not disclosed to any third party, it is not used for advertisment or spam.</p>
        <p>
          Users can request data deletion by contacting the developer on the{' '}
          <a href="https://www.facebook.com/SevenCastlesFanpage" target="blank">
            Seven Castles Application Facebook page
          </a>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
