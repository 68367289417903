import ccsonTileTypesJson from '../config/ccson/ccson_tilemap.json';
import ccsonTileCount from '../config/ccson/ccson_tilecount.json';
import koenigsburgTileTypesJson from '../config/koenigsburg/koenigsburg_tilemap.json';
import koenigsburgTileCount from '../config/koenigsburg/koenigsburg_tilecount.json';
import { TileBasedGameFamilyType } from './tileGameConfig';

export interface NodeDef {
  id: number;
  type: string;
  x: number;
  y: number;
}

export interface TileDef {
  type: number;
  count?: number;
  sides: string;
  nodes: Array<NodeDef>;
  rot?: number;
}

const ccsonTileTypes: Record<number, TileDef> = ccsonTileTypesJson as Record<number, TileDef>;
const koenigsburgTileTypes: Record<number, TileDef> = koenigsburgTileTypesJson as Record<number, TileDef>;

const getTileDef = (type: number, family: TileBasedGameFamilyType): TileDef => {
  if (family === 'koenigsburg') {
    return koenigsburgTileTypes[type];
  } else return ccsonTileTypes[type];
};

export { ccsonTileCount, koenigsburgTileCount, getTileDef };
