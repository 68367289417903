import axiosClient from './axiosClient';
import store from '../reducers/store';
import { setUser } from '../reducers/userReducer';
import { deleteToken, saveToken } from '../login/localStorage';
import { AxiosRequestConfig } from 'axios';
import { UserDTO } from '../types/core';

const endpoint = 'user';

const loginWithFb = (token: string) => {
  console.log('loginWithFb', token);
  const requestConfig: AxiosRequestConfig = {
    url: `${endpoint}/login/fb`,
    method: 'post',
    data: {
      token
    },
    withCredentials: false
  };
  return axiosClient
    .request(requestConfig)
    .then((response) => {
      saveToken(response.data.token);
      return response;
    })
    .catch((error) => {
      deleteToken();
      console.log('FB login error', error);
      return Promise.reject(error);
    });
};

const loginWithGoogle = (token: string) => {
  console.log('loginWithGoogle', token);
  const requestConfig: AxiosRequestConfig = {
    url: `${endpoint}/login/google`,
    method: 'post',
    data: {
      token
    },
    withCredentials: false
  };
  return axiosClient
    .request(requestConfig)
    .then((response) => {
      saveToken(response.data.token);
      return response;
    })
    .catch((error) => {
      deleteToken();
      console.log('Google login error', error);
      return Promise.reject(error);
    });
};

const loginWithApple = (token: string, userName: string) => {
  console.log('loginWithApple', token);
  const requestConfig: AxiosRequestConfig = {
    url: `${endpoint}/login/apple`,
    method: 'post',
    data: {
      token,
      userName
    },
    withCredentials: false
  };
  return axiosClient
    .request(requestConfig)
    .then((response) => {
      saveToken(response.data.token);
      return response;
    })
    .catch((error) => {
      deleteToken();
      console.log('Apple login error', error);
      return Promise.reject(error);
    });
};

const getUserInfo = (): Promise<UserDTO> => {
  const requestConfig: AxiosRequestConfig = {
    url: `${endpoint}/me`,
    method: 'get',
    withCredentials: true
  };
  return axiosClient.request(requestConfig).then((response) => {
    store.dispatch(setUser(response.data.user));
    window.runtimeConfig.REACT_APP_WS_URL = response.data.config.wsUrl;
    return response.data.user;
  });
};

const convertPoints = (): Promise<void> => {
  const requestConfig: AxiosRequestConfig = {
    url: `${endpoint}/convert`,
    method: 'post'
  };
  return axiosClient.request(requestConfig);
};

export { loginWithFb, loginWithGoogle, getUserInfo, convertPoints, loginWithApple };
