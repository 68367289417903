import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const slice = createSlice({
  name: 'guide',
  initialState,
  reducers: {
    showGuide: () => true,
    hideGuide: () => false
  },
  extraReducers: (/* builder */) => {
    // TODO hide creategame when join game
    //builder.addCase(appStatusActions.setGame, () => false )
  }
});

export const guideActions = slice.actions;

export default slice.reducer;
