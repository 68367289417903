import React, { FC } from 'react';
import GameFamilies from './guide/GameFamilies';
import classes from './PageCover.module.scss';

const PageCover: FC = () => {
  return (
    <div className={classes.coverCont}>
      <GameFamilies showTitle={false} />
    </div>
  );
};

export default PageCover;
