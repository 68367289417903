import React, { FC } from 'react';
import classes from './KTileItem.module.scss';
import { getContainerStyle, getTileCoords, getTileHeight, getTileStyle, getTileWidth } from './KTileAsset';
import { MeepleDTO, MeepleType } from '../../types/ccson_env';
import { Pos } from '@envclient/envcore/src/types/frontendTypes';
import { KActionDTO, KTileDTO } from '../../types/koenigsburg_env';
import KMeeple from './KMeeple';
import KNode from './KNode';
import classNames from 'classnames';
import { getTileDef, KTileDef, NodeDef } from '../../config/kTileMapConfig';

function calculateUnrotatedNodePos(meepleNode: NodeDef | undefined, rot: number, rhombus: boolean) {
  const tileWidth = getTileWidth(rhombus);
  const tileHeight = getTileHeight(rhombus);
  if (!meepleNode) return;
  const xCentered = meepleNode.x - 0.5;
  const yCentered = meepleNode.y - 0.5;
  const rad = rot * (Math.PI / 180);
  const meepleWidth = 41;
  const meepleHeight = 50;
  const xUnrotated = Math.cos(rad) * xCentered - Math.sin(rad) * yCentered;
  const yUnrotated = Math.sin(rad) * xCentered + Math.cos(rad) * yCentered;
  const left = Math.round((xUnrotated + 0.5) * tileWidth - meepleWidth / 2);
  const top = Math.round((yUnrotated + 0.5) * tileHeight - meepleHeight / 2);
  return { left, top };
}

export { calculateUnrotatedNodePos as calculateMeeplePos };

interface TileItemProps {
  tile: KTileDTO;
  active: boolean;
  last?: boolean;
  onClick?: (action: KActionDTO) => void;
  onMeepleClick?: (meeple: MeepleDTO) => void;
  posActions?: Array<KActionDTO>;
  preferredMeepleType?: MeepleType;
  rotateThreshold?: number;
  overlay?: boolean;
  selectedAction?: KActionDTO;
}

const KTileItem: FC<TileItemProps> = (props) => {
  const { tile, active, last, overlay = false, posActions, selectedAction } = props;
  const { x, y, type, rot, meeple } = tile;

  const tileDef: KTileDef = getTileDef(type);
  const nodes = tileDef.nodes;

  const rhombus = !!tileDef.rhombus;

  const tileWidth = getTileWidth(rhombus);
  const tileHeight = getTileHeight(rhombus);

  // For Kburg just rotate actions for now.
  const findNextAction = () => {
    if (!selectedAction || !posActions) return null;
    let index = posActions.indexOf(selectedAction);
    if (index === -1) index = 0;
    index++;
    if (index >= posActions.length) index = 0;
    return posActions[index];
  };

  const handleClick = () => {
    if (!props.active || !props.onClick) return;
    //const action = findClosestPos(e.nativeEvent);
    const action = findNextAction();
    if (action) props.onClick(action);
  };

  const handleMeepleClick = (meeple: MeepleDTO) => {
    if (!props.active || !props.onMeepleClick) return;
    props.onMeepleClick(meeple);
  };

  const tileStyle = getTileStyle(x, y, rhombus);

  const coords = getTileCoords(type) as Pos;

  const contStyle = getContainerStyle(coords, rot);

  // calc unrotated meeple pos
  let meeplePos;
  if (meeple) {
    const meepleNode = nodes.find((node) => node.id === meeple.nodeId);
    meeplePos = calculateUnrotatedNodePos(meepleNode, rot, rhombus);
  }

  const tileClasses = classNames(classes.tileItem, classes.koenigsburg, {
    [classes.overlay]: overlay,
    [classes.rhombus]: rhombus
  });
  const rotClasses = classNames(classes.rotCont, classes.koenigsburg, {
    [classes.rhombus]: rhombus
  });

  const overlayProps = { active: false, overlay: true, tile: selectedAction?.overlayDTO as KTileDTO, last: false };

  return (
    <>
      <div className={tileClasses} style={tileStyle}>
        <div className={rotClasses} style={contStyle} onClick={handleClick}>
          <div className={classes.innerCont}>
            {last && <div className={classes.last}></div>}
            {active && <div className={classes.act}></div>}
            {nodes.map((node) => (
              <KNode key={node.id} node={node} active={active} tileWidth={tileWidth} tileHeight={tileHeight} />
            ))}
          </div>
        </div>
        {meeple && <KMeeple meeple={meeple} pos={meeplePos} active={active} onClick={handleMeepleClick} />}
      </div>
    </>
  );
};

export default React.memo(KTileItem);
