import React, { FC } from 'react';
import classes from './KTileItem.module.scss';
import { getTileType, NodeDef, TileDef } from '../../config/tileMapConfig';
import { getContainerStyle, getTileCoords, getTileHeight, getTileStyle, getTileWidth } from './KTileAsset';
import { MeepleDTO, MeepleType } from '../../types/ccson_env';
import { Pos } from '@envclient/envcore/src/types/frontendTypes';
import { TileBasedGameFamilyType } from '../../config/tileGameConfig';
import { KActionDTO, KoenigsburgTileDTO } from '../../types/koenigsburg_env';
import KMeeple from './KMeeple';
import KNode from './KNode';
import classNames from 'classnames';

function calculateUnrotatedNodePos(meepleNode: NodeDef | undefined, rot: number, family: TileBasedGameFamilyType) {
  const tileWidth = getTileWidth(family);
  const tileHeight = getTileHeight(family);
  if (!meepleNode) return;
  const xCentered = meepleNode.x - 0.5;
  const yCentered = meepleNode.y - 0.5;
  const rad = rot * (Math.PI / 180);
  const meepleWidth = 41;
  const meepleHeight = 50;
  const xUnrotated = Math.cos(rad) * xCentered - Math.sin(rad) * yCentered;
  const yUnrotated = Math.sin(rad) * xCentered + Math.cos(rad) * yCentered;
  const left = Math.round((xUnrotated + 0.5) * tileWidth - meepleWidth / 2);
  const top = Math.round((yUnrotated + 0.5) * tileHeight - meepleHeight / 2);
  return { left, top };
}

export { calculateUnrotatedNodePos as calculateMeeplePos };

interface TileItemProps {
  tile: KoenigsburgTileDTO;
  active: boolean;
  last?: boolean;
  onClick?: (action: KActionDTO) => void;
  onMeepleClick?: (meeple: MeepleDTO) => void;
  posActions?: Array<KActionDTO>;
  preferredMeepleType?: MeepleType;
  rotateThreshold?: number;
  overlay?: boolean;
  selectedAction?: KActionDTO;
}
const family = 'koenigsburg';

const KTileItem: FC<TileItemProps> = (props) => {
  const {
    tile,
    active,
    last,
    preferredMeepleType,
    rotateThreshold = 1000,
    overlay = false,
    posActions,
    selectedAction
  } = props;
  const { x, y, type, rot, meeple } = tile;

  const tileDef: TileDef = getTileType(type, family);
  const nodes = tileDef.nodes;

  const tileWidth = getTileWidth(family);
  const tileHeight = getTileHeight(family);

  const findClosestPos = (nativeEvent: globalThis.MouseEvent) => {
    const { posActions, tile } = props;
    const { rot } = tile;
    if (!posActions) return null;
    const nodes = tileDef.nodes;
    let minDistance = 1000000;
    let closestNode;

    function distance2(x1: number, y1: number, x2: number, y2: number) {
      return (x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2);
    }

    for (const node of nodes) {
      if (!getNodeActions(node).length) continue;
      const d = distance2(node.x * tileWidth, node.y * tileHeight, nativeEvent.offsetX, nativeEvent.offsetY);
      if (d < minDistance) {
        minDistance = d;
        closestNode = node;
      }
    }
    if (closestNode && minDistance < rotateThreshold) {
      const closestPos = getNodeActions(closestNode);
      console.log('closestPos', closestPos, meeple);
      // prefer second choice (eg. meeple or giant) if that is preferred type
      if (closestPos.length > 1 && closestPos[1].tileDTO.meeple?.type === preferredMeepleType) {
        return closestPos[1];
      }
      return closestPos[0];
    }
    // try to rotate forw
    let nextRotPos = posActions.find((action: KActionDTO) => action.tileDTO.rot > rot);
    //try to rotate backw
    if (!nextRotPos) nextRotPos = posActions.find((action: KActionDTO) => action.tileDTO.rot < rot);
    // return if found
    if (nextRotPos) return nextRotPos;
  };

  // For Kburg just rotate actions for now.
  const findNextAction = () => {
    if (!selectedAction || !posActions) return null;
    let index = posActions.indexOf(selectedAction);
    if (index === -1) index = 0;
    index++;
    if (index >= posActions.length) index = 0;
    return posActions[index];
  };

  const handleClick = () => {
    if (!props.active || !props.onClick) return;
    //const action = findClosestPos(e.nativeEvent);
    const action = findNextAction();
    if (action) props.onClick(action);
  };

  const handleMeepleClick = (meeple: MeepleDTO) => {
    if (!props.active || !props.onMeepleClick) return;
    props.onMeepleClick(meeple);
  };

  const getNodeActions = (node: NodeDef) => {
    const { tile, posActions } = props;
    const { rot } = tile;
    if (!posActions) return [];
    return posActions.filter((action) => {
      return action.tileDTO.rot === rot && action.tileDTO.meeple && action.tileDTO.meeple.nodeId === node.id;
    });
  };

  const tileStyle = getTileStyle(x, y, family);

  const coords = getTileCoords(type, family) as Pos;

  const contStyle = getContainerStyle(coords, rot, family);

  // calc unrotated meeple pos
  let meeplePos;
  if (meeple) {
    const meepleNode = nodes.find((node) => node.id === meeple.nodeId);
    meeplePos = calculateUnrotatedNodePos(meepleNode, rot, family);
  }

  const overlayProps = { ...props, overlay: true, tile: tile.overlayTile as KoenigsburgTileDTO };

  const tileClasses = classNames(classes.tileItem, classes[family as string]);
  const rotClasses = classNames(classes.rotCont, classes[family as string], { [classes.overlay]: overlay });

  return (
    <>
      <div className={tileClasses} style={tileStyle}>
        <div className={rotClasses} style={contStyle} onClick={handleClick}>
          <div className={classes.innerCont}>
            {last && <div className={classes.last}></div>}
            {active && <div className={classes.act}></div>}
            {nodes.map((node) => (
              <KNode
                key={node.id}
                node={node}
                active={active && getNodeActions(node).length > 0}
                tileWidth={tileWidth}
                tileHeight={tileHeight}
              />
            ))}
          </div>
        </div>
        {meeple && <KMeeple meeple={meeple} pos={meeplePos} active={active} onClick={handleMeepleClick} />}
      </div>
      {tile.overlayTile && <KTileItem {...overlayProps} />}
    </>
  );
};

export default React.memo(KTileItem);
