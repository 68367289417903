import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import classes from './GameList.module.scss';
import WsConnection from '@envclient/envcore/src/service/WsConnection';
import Button from '@envclient/envcore/src/components/Button';
import { FaPlus } from 'react-icons/fa';
import { RootState } from '@envclient/envcore/src/reducers/store';
import { GameConfigDTO, RoomDTO } from '@envclient/envcore/src/types/core';
import { AppStatus } from '@envclient/envcore/src/reducers/appStatusReducer';
import { isSupported } from '../utils/game';

const GameList: FC<Props> = ({ showCreate, onShowCreate }) => {
  const user = useSelector((state: RootState) => state.user);
  const appStatus = useSelector((state: RootState) => state.appStatus);
  const gameList = useSelector((state: RootState) => state.gameList);

  const handleJoin = (room: RoomDTO) => {
    if (!user) return;
    const gameRoom = gameList.find((g) => g.room.roomId === room.roomId);
    const roomGameConfig = window.gameConfigs.find((g) => g.type === gameRoom?.gameType) as GameConfigDTO;
    if (!isSupported(roomGameConfig, window.appConfig.version)) return;
    WsConnection.send({ type: 'JOIN', room });
  };

  const renderList = () => {
    return gameList.map((g) => {
      return (
        <li className={classes.listItem} key={g.room.roomId}>
          <div className={classes.itemCont}>
            <span className={classes.roomName}>{g.room.roomName}</span>
            <span className={classes.tilesLeft}>{g.gameInfo}</span>
            <Button onClick={() => handleJoin(g.room)} disabled={g.locked} secondary>
              Join
            </Button>
          </div>
          <div className={classes.users}>{g.room.userList.map((u) => u.displayName).join(', ')}</div>
        </li>
      );
    });
  };

  return (
    <div className={classes.mainCont}>
      <div className={classes.header}>
        <div className={classes.title}>Games</div>
        <Button onClick={onShowCreate} disabled={showCreate || appStatus === AppStatus.GAME}>
          <FaPlus />
          Create
        </Button>
      </div>

      <div className={classes.body}>
        <ul className={classes.list}>{renderList()}</ul>
      </div>
    </div>
  );
};

interface Props {
  showCreate: boolean;
  onShowCreate: () => void;
}

export default GameList;
