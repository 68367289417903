import React, { FC } from 'react';
import classes from './Button.module.scss';
import classNames from 'classnames';

const Button: FC<ButtonProps> = (props) => {
  const btnClasses = classNames(classes.bg, {
    [classes.disabled]: props.disabled,
    [classes.secondary]: props.secondary,
    [classes.primary]: props.primary,
    [classes.small]: props.small,
    [classes.toggled]: props.toggled
  });

  const onClick = () => {
    if (!props.disabled) props.onClick();
  };

  return (
    <div className={btnClasses} onClick={onClick}>
      {props.children}
    </div>
  );
};

interface ButtonProps {
  onClick: () => void;
  disabled?: boolean;
  primary?: boolean;
  secondary?: boolean;
  small?: boolean;
  toggled?: boolean;
}

export default Button;
