import React, { FC } from 'react';
import { useTimeout } from 'react-use';

import classes from './Meeple.module.scss';
import { MeepleDTO } from '../../types/ccson_env';
import { CSSPos } from '@envclient/envcore/src/types/frontendTypes';
import { colorMap } from './CcsonPlayerItem';

const Meeple: FC<MeepleProps> = (props) => {
  const { meeple, pos, inMeepleCont, active, onClick } = props;
  const { type, color, score, recovered } = meeple;

  // meeple/meepleBtn, giant/giantBtn, etc
  let meepleClass = classes[type];
  if (inMeepleCont) meepleClass = classes.meepleInCont + ' ' + classes[type + 'Btn'];

  const bgClassName = type + '_' + colorMap[color].name;
  const bgClass = classes[bgClassName];

  const bgStyle = {
    display: recovered ? 'none' : 'block'
  };

  const tfStyle = {
    backgroundColor: recovered ? '#000' : '#fff',
    color: recovered ? colorMap[color].code : '#000',
    display: score > 0 ? 'inline' : 'none'
  };

  const style: CSSPos = pos as CSSPos;

  const handleClick = () => {
    onClick?.(meeple);
  };

  // place meeple animation
  const [isReady] = useTimeout(1);
  const startStyle: CSSPos = { top: -500, left: 500 };

  return (
    <div className={meepleClass} style={active && !isReady() ? startStyle : style} onClick={handleClick}>
      <div className={classes.bg + ' ' + bgClass} style={bgStyle}></div>
      <p className={classes.tf} style={tfStyle}>
        {score}
      </p>
    </div>
  );
};

interface MeepleProps {
  meeple: MeepleDTO;
  pos?: CSSPos;
  inMeepleCont?: boolean;
  active?: boolean;
  onClick?: (meeple: MeepleDTO) => void;
}

export default Meeple;
