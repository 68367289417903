import { Reducer } from 'redux';
import { UserDTO } from '../types/core';
import { SET_GAME_USER_LIST, JOIN_GAME, LEAVE_GAME } from './gameActions';

const initialState: UserDTO[] = [];

function addUser(userList: UserDTO[], user: UserDTO) {
  const userListCopy = userList.concat();
  if (!userList.find((value) => value.userId === user.userId)) {
    userListCopy.push(user);
  }
  return userListCopy;
}

function removeUser(userList: UserDTO[], user: UserDTO) {
  const index = userList.findIndex((value) => value.userId === user.userId);
  return userList.slice(0, index).concat(userList.slice(index + 1));
}

const gameUserlistReducer: Reducer<UserDTO[]> = (state = initialState, action) => {
  switch (action.type) {
    case SET_GAME_USER_LIST:
      return action.userList;
    case JOIN_GAME:
      return addUser(state, action.user);
    case LEAVE_GAME:
      return removeUser(state, action.user);
    default:
      return state;
  }
};

export default gameUserlistReducer;
