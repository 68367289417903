import React, { FC, useState } from 'react';
import classes from './WinnersDialog.module.scss';
import Button from '@envclient/envcore/src/components/Button';
import { RoomDTO, UserDTO } from '@envclient/envcore/src/types/core';
import Modal from './Modal';
import { useSelector } from 'react-redux';
import WsConnection from '@envclient/envcore/src/service/WsConnection';
import LobbyUserList from './LobbyUserList';
import { RootState } from '@envclient/envcore/src/reducers/store';

const AddPlayersDialog: FC<Props> = ({ onClose }) => {
  const lobbyUserList = useSelector((state: RootState) => state.lobby.userList);
  const currentRoom = useSelector((state: RootState) => state.currentRoom) as RoomDTO;
  const myUser = useSelector((state: RootState) => state.user) as UserDTO;
  const [inviteCount, setInviteCount] = useState(0);

  const handleInvite = (user: UserDTO) => {
    WsConnection.send({ type: 'INVITE', room: currentRoom, user });
    setInviteCount(inviteCount + 1);
  };

  return (
    <Modal>
      <div className={classes.mainCont}>
        <div className={classes.bg}></div>
        <div className={classes.panel}>
          <div className={classes.header}>
            <div className={classes.title}>Invite players</div>
          </div>
          <div className={classes.body}>
            <LobbyUserList myUser={myUser} userList={lobbyUserList} onInvite={handleInvite} />
            {inviteCount > 0 && (
              <div>
                You have invited {inviteCount} user{inviteCount > 1 ? 's' : ''} to your game.
              </div>
            )}
          </div>
          <div className={classes.btnBar}>
            <Button onClick={onClose}>Done</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

interface Props {
  onClose: () => void;
}
export default AddPlayersDialog;
