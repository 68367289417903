// Modal.js
import React, { ReactChildren } from 'react';
import { createPortal } from 'react-dom';

// We get hold of the div with the id modal that we have created in index.html
const modalRoot: HTMLElement = document.getElementById('modal') as HTMLElement;

class Modal extends React.Component {
  element: HTMLElement;

  constructor(props: Props) {
    super(props);
    // We create an element div for this modal
    this.element = document.createElement('div');
  }
  // We append the created div to the div#modal
  componentDidMount() {
    modalRoot.appendChild(this.element);
  }
  /**
   * We remove the created div when this Modal Component is unmounted
   * Used to clean up the memory to avoid memory leak
   */
  componentWillUnmount() {
    modalRoot.removeChild(this.element);
  }
  render() {
    return createPortal(this.props.children, this.element);
  }
}

interface Props {
  children: ReactChildren;
}
export default Modal;
