import { AppleResponse } from '../types/frontendTypes';

export const appleLoginMobile = (): Promise<AppleResponse> => {
  return new Promise((resolve, reject) => {
    window.cordova.plugins.SignInWithApple.signin(
      { requestedScopes: [0, 1] },
      function (response: AppleResponse) {
        console.log('Apple response', response);
        resolve(response);
      },
      function (err: { error: string; code: string }) {
        console.error(err);
        reject(err);
      }
    );
  });
};
