import { Reducer } from 'redux';
import { InviteMessageDTO } from '../types/core';
import { INVITE, INVITE_CONSUME } from './actions';

const initialState: InviteMessageDTO[] = [];

export const addInvitation = (payload: InviteMessageDTO) => ({ type: INVITE, payload });

export const consumeInvitation = (payload: InviteMessageDTO) => ({ type: INVITE_CONSUME, payload });

const invitationReducer: Reducer<InviteMessageDTO[]> = (state = initialState, action) => {
  if (action.type === INVITE) {
    // only one concurrent invite - migth become a serial processing later
    if (state.length > 0) return state;
    return state.concat(action.payload);
  } else if (action.type === INVITE_CONSUME) {
    return state.filter((e) => e !== action.payload);
  }
  return state;
};

export default invitationReducer;
