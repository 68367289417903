import React, { FC } from 'react';
import { TileBasedGameFamilyType } from '../../config/tileGameConfig';
import { GameFamilyType } from '../../types/ccson_env';
import createTileItem from '../../envs/ccson/createTileItem';
import classes from './Guide.module.scss';

const GuideTileItem: FC<{ tc: string; family: GameFamilyType }> = ({ tc, family }) => {
  const [tileType, ccsonTileCount] = tc.split(':');
  return (
    <div className={classes.tileCont}>
      {createTileItem(Number(tileType), family as TileBasedGameFamilyType)}
      <div className={classes.label}>
        {+tileType} x {ccsonTileCount}
      </div>
    </div>
  );
};

export default GuideTileItem;
