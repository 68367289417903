import React, { FC, MouseEvent, ReactNode, TouchEvent, useEffect, useRef, useState, WheelEvent } from 'react';

export interface PrismaZoomProps {
  currentZoom?: number;
  minZoom?: number;
  maxZoom?: number;
  children?: ReactNode;
  className?: string;
  style?: Record<string, unknown>;
  scrollVelocity?: number;
  animDuration?: number;
  zoomSpeedInverse?: number;
  onZoomChange: (newZoom: number) => void;
}

const PrismaZoom: FC<PrismaZoomProps> = ({
  currentZoom = 0.5,
  // Minimum zoom ratio
  minZoom = 0.2,
  // Maximum zoom ratio
  maxZoom = 2,
  // Zoom increment or decrement on each scroll wheel detection
  scrollVelocity = 0.005,
  // Animation duration (in seconds)
  animDuration = 0,
  // zoom step is diveded by this
  zoomSpeedInverse = 200,
  className,
  children,
  style = {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onZoomChange = () => {}
}: PrismaZoomProps) => {
  const [zoom, setZoom] = useState(currentZoom);
  // Transform translateX value property
  const [posX, setPosX] = useState(0);
  // Transform translateY value property
  const [posY, setPosY] = useState(0);
  // Cursor style property
  const [cursor, setCursor] = useState('auto');
  // trans dur
  const [transitionDuration, setTransitionDuration] = useState(animDuration);

  // Last cursor position
  const [lastCursor, setLastCursor] = useState<{ posX: number; posY: number }>();
  // Last touch position
  const [lastTouch, setLastTouch] = useState<{ posX: number; posY: number }>();
  // Last calculated distance between two fingers in pixels
  const [lastTouchDistance, setLastTouchDistance] = useState<number>();

  const layoutRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setZoom(currentZoom);
  }, [currentZoom]);
  /**
   * Calculates new translate positions for CSS transformations.
   * @param  {Number} x     Relative (rect-based) X position in pixels
   * @param  {Number} y     Relative (rect-based) Y position in pixels
   * @param  {Number} zoom  Scale value
   * @return {Array}        New X and Y positions
   */
  /*
  const getNewPosition = (x: number, y: number, zoom: number) => {
    const [prevZoom, prevPosX, prevPosY] = [zoom, posX, posY];

    if (zoom === 1) {
      return [0, 0];
    }

    if (zoom > prevZoom) {
      // Get container coordinates
      // eslint-disable-next-line react/no-string-refs
      if (!layoutRef.current) return [posX, posY];
      const rect = layoutRef.current.getBoundingClientRect();

      // Retrieve rectangle dimensions and mouse position
      const [centerX, centerY] = [rect.width / 2, rect.height / 2];
      const [relativeX, relativeY] = [x - rect.left - window.pageXOffset, y - rect.top - window.pageYOffset];

      // If we are zooming down, we must try to center to mouse position
      const [absX, absY] = [(centerX - relativeX) / prevZoom, (centerY - relativeY) / prevZoom];
      const ratio = zoom - prevZoom;
      return [prevPosX + absX * ratio, prevPosY + absY * ratio];
    } else {
      // If we are zooming down, we shall re-center the element
      return [(prevPosX * (zoom - 1)) / (prevZoom - 1), (prevPosY * (zoom - 1)) / (prevZoom - 1)];
    }
  };
  */

  /**
   * Determines cursor style.
   * @param  {Boolean} canMoveOnX Element can be panned on the X axis
   * @param  {Boolean} canMoveOnY Element can be panned on the Y axis
   * @return {String}             Cursor style
   */
  const getCursor = (canMoveOnX: boolean, canMoveOnY: boolean) => {
    if (canMoveOnX && canMoveOnY) {
      return 'move';
    } else if (canMoveOnX) {
      return 'ew-resize';
    } else if (canMoveOnY) {
      return 'ns-resize';
    } else {
      return 'auto';
    }
  };

  /**
   * Moves the element by incrementing its position with given X and Y values.
   * @param  {Number} shiftX             Position change to apply on X axis in pixels
   * @param  {Number} shiftY             Position change to apply on Y axis in pixels
   * @param  {Number} transitionDuration Transition duration (in seconds)
   */
  const move = (shiftX: number, shiftY: number, transitionDuration = 0) => {
    setPosX(posX + shiftX);
    setPosY(posY + shiftY);

    const cursor = getCursor(true, true);
    setCursor(cursor);
    setTransitionDuration(transitionDuration);
  };

  /**
   * Event handler on scroll.
   * @param  {MouseEvent} event Mouse event
   */
  const handleMouseWheel = (event: WheelEvent<HTMLElement>) => {
    //event.preventDefault();

    // Determine if we are increasing or decreasing the zoom
    const increaseZoom = event.deltaY < 0;
    // Set the new zoom value
    let newZoom = zoom;

    if (increaseZoom) {
      if (zoom + scrollVelocity < maxZoom) {
        newZoom = zoom + scrollVelocity;
      } else {
        newZoom = maxZoom;
      }
    } else {
      if (zoom - scrollVelocity > minZoom) {
        newZoom = zoom - scrollVelocity;
      } else {
        newZoom = minZoom;
      }
    }

    // this should be smaller for touch scroll (2 fingers)
    //this.setState({ zoom, /*posX, posY,*/ transitionDuration: 0.1 });
    setTransitionDuration(0.1);
    setZoom(newZoom);
    onZoomChange(newZoom);
  };

  /**
   * Event handler on mouse down.
   * @param  {MouseEvent} event Mouse event
   */
  const handleMouseStart = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setLastCursor({ posX: event.pageX, posY: event.pageY });
  };

  /**
   * Event handler on mouse move.
   * @param  {MouseEvent} event Mouse event
   */
  const handleMouseMove = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (!lastCursor) {
      return;
    }

    const [posX, posY] = [event.pageX, event.pageY];
    const shiftX = posX - lastCursor.posX;
    const shiftY = posY - lastCursor.posY;

    move(shiftX, shiftY, 0);
    setLastCursor({ posX, posY });
  };

  /**
   * Event handler on mouse up or mouse out.
   * @param  {MouseEvent} event Mouse event
   */
  const handleMouseStop = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setLastCursor(undefined);
    setCursor('auto');
  };

  /**
   * Event handler on touch start.
   * Zoom-in at the maximum scale if a double tap is detected.
   * @param  {TouchEvent} event Touch event
   */
  const handleTouchStart = (event: TouchEvent<HTMLElement>) => {
    //event.preventDefault();

    const [posX, posY] = [event.touches[0].pageX, event.touches[0].pageY];

    setLastTouch({ posX, posY });
  };

  /**
   * Event handler on touch move.
   * Either move the element using one finger or zoom-in with a two finger pinch.
   * @param  {TouchEvent} event Touch move
   */
  const handleTouchMove = (event: TouchEvent<HTMLElement>) => {
    //event.preventDefault();

    if (!lastTouch) {
      return;
    }

    if (event.touches.length === 1) {
      const [posX, posY] = [event.touches[0].pageX, event.touches[0].pageY];
      // If we detect only one point, we shall just move the element
      const shiftX = posX - lastTouch.posX;
      const shiftY = posY - lastTouch.posY;

      move(shiftX, shiftY);

      // Save data for the next move
      setLastTouch({ posX, posY });
      setLastTouchDistance(undefined);
    } else if (event.touches.length > 1) {
      // If we detect two points, we shall zoom up or down
      const [pos1X, pos1Y] = [event.touches[0].pageX, event.touches[0].pageY];
      const [pos2X, pos2Y] = [event.touches[1].pageX, event.touches[1].pageY];
      const distance = Math.sqrt(Math.pow(pos2X - pos1X, 2) + Math.pow(pos2Y - pos1Y, 2));

      if (lastTouchDistance && distance && distance !== lastTouchDistance) {
        setZoom(zoom + (distance - lastTouchDistance) / zoomSpeedInverse);
        if (zoom > maxZoom) {
          setZoom(maxZoom);
        } else if (zoom < minZoom) {
          setZoom(minZoom);
        }

        // Change position using the center point between the two fingers
        // centering DISABLED
        //const [centerX, centerY] = [(pos1X + pos2X) / 2, (pos1Y + pos2Y) / 2];
        //const [posX, posY] = this.getNewPosition(centerX, centerY, zoom);
        //this.setState({ zoom /*, posX, posY */, transitionDuration: 0 });
        setTransitionDuration(0);
      }

      // Save data for the next move
      setLastTouch({ posX: pos1X, posY: pos1Y });
      setLastTouchDistance(distance);
    }
    onZoomChange(zoom);
  };

  /**
   * Event handler on touch end or touch cancel.
   * @param  {TouchEvent} event Touch move
   */
  const handleTouchStop = () => {
    setLastTouch(undefined);
    setLastTouchDistance(undefined);
  };

  //render

  const currStyle = Object.assign({}, style, {
    transform: `translate3d(${posX}px, ${posY}px, 0) scale(${zoom})`,
    transition: `transform ease-out ${transitionDuration}s`,
    cursor: cursor,
    touchAction: 'none',
    willChange: 'transform',
    height: '100%'
  });

  const attr = {
    style: currStyle,
    className: className,
    onWheel: handleMouseWheel,
    onMouseDown: handleMouseStart,
    onMouseMove: handleMouseMove,
    onMouseUp: handleMouseStop,
    onMouseLeave: handleMouseStop,
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove,
    onTouchEnd: handleTouchStop,
    onTouchCancel: handleTouchStop
  };

  return (
    <div ref={layoutRef} {...attr}>
      {children}
    </div>
  );
};

export default PrismaZoom;
