import React, { FC } from 'react';
import { CcsonEnvDTO } from '../../types/ccson_env';
import TileMap from './TileMap';
import classes from './GameCanvas.module.scss';
import { GameCanvasProps } from '@envclient/envcore/src/components/GameView';

/**
 * Wrapper for ccson specific comps: i.e. TileMap + MeepleCont.
 */
const CcsonGameCanvas: FC<GameCanvasProps> = ({ env, active, game, onSubmit }) => {
  const { tiles, lastTile, currentActions, tilesLeft } = env as CcsonEnvDTO;
  const startBtnVisible = game.status === 'IDLE';

  return (
    <>
      {tiles && (
        <TileMap
          tileData={tiles}
          lastTile={lastTile}
          currentActions={currentActions}
          active={active}
          onSubmit={onSubmit}
        />
      )}
      <div className={classes.tilesLeft}>{!startBtnVisible && `Tiles: ${tilesLeft}`}</div>
    </>
  );
};

export default CcsonGameCanvas;
