import React, { FC, useEffect, useState } from 'react';
import classes from './Guide.module.scss';
import Button from '@envclient/envcore/src/components/Button';
import { FaArrowLeft, FaTimes } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { guideActions } from '@envclient/envcore/src/reducers/guideReducer';
import classNames from 'classnames';
import { useAppSelector } from '@envclient/envcore/src/reducers/store';
import GameFamilies from './GameFamilies';
import SeaGuide from './SeaGuide';
import { gameFamilies, GameFamily } from '../../config/gameFamilyConfig';
import { useParams } from 'react-router-dom';
import { TileBasedGameFamilyType } from '../../config/tileGameConfig';
import CarcassonneGuide from './CarcassonneGuide';
import CastlesGuide from './CastlesGuide';

const Guide: FC = () => {
  const showGuide = useAppSelector((state) => state.guide);
  const dispatch = useDispatch();
  const { gameFamily } = useParams<{ gameFamily: TileBasedGameFamilyType }>();

  const [selectedFamily, setSelectedFamily] = useState<GameFamily | null>();

  const findFamily = (familyName: TileBasedGameFamilyType): GameFamily | undefined => {
    return gameFamilies.find((gf) => gf.name === familyName);
  };

  useEffect(() => {
    if (gameFamily) setSelectedFamily(findFamily(gameFamily));
    else setSelectedFamily(null);
  }, [gameFamily]);

  const hideGuide = () => {
    dispatch(guideActions.hideGuide());
  };

  const guideClasses = classNames(classes.mainCont, { [classes.show]: showGuide });

  const handleSelectFamily = (family: GameFamily) => {
    setSelectedFamily(family);
  };

  const back = () => {
    setSelectedFamily(null);
  };

  return (
    <div className={guideClasses}>
      <div className={classes.header}>
        <div className={classes.leftGroup}>
          {selectedFamily && (
            <Button secondary onClick={back}>
              <FaArrowLeft />
            </Button>
          )}
          <div className={classes.title}>Guide {selectedFamily && `/ ${selectedFamily?.title}`}</div>
        </div>
        <div className={classes.closeBtn}>
          <Button secondary onClick={hideGuide}>
            <FaTimes />
          </Button>
        </div>
      </div>
      {!selectedFamily && <GameFamilies onSelectFamily={handleSelectFamily} />}
      <div className={classes.body}>
        {selectedFamily?.type === 'sea' && <SeaGuide />}
        {selectedFamily?.type === 'ccson' && <CarcassonneGuide />}
        {selectedFamily?.type === 'castle' && <CastlesGuide />}
      </div>
    </div>
  );
};

export default Guide;
