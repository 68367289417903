import { Reducer } from 'redux';
import { GameEventDTO } from '../types/core';
import { AppStatus } from './appStatusReducer';
import { GAME_EVENT, GAME_EVENT_CONSUME } from './gameActions';

const initialState: GameEventDTO[] = [];

const gameEventsReducer: Reducer<GameEventDTO[]> = (state = initialState, action) => {
  if (action.type === GAME_EVENT) {
    // push new events into queue
    return state.concat([action.event]);
  } else if (action.type === GAME_EVENT_CONSUME) {
    // filter out consumed event
    return state.filter((e) => e !== action.event);
  } else if (action.payload === AppStatus.LOBBY) {
    return initialState;
  }

  return state;
};

export default gameEventsReducer;
