import { Reducer } from 'redux';
import { UserDTO } from '../types/core';
import { USER } from './actions';

const initialState = null;

export const setUser = (payload: UserDTO | null) => ({ type: USER, payload });

const userReducer: Reducer<UserDTO | null> = (state = initialState, action) => {
  if (action.type === USER) {
    return action.payload;
  }
  return state;
};

export default userReducer;
