import React, { FC } from 'react';
import seaGuide from '../../assets/guide/sea1.png';
import { ccsonTileCount } from '../../config/tileMapConfig';
import classes from './Guide.module.scss';
import GuideTileItem from './GuideTileItem';

const SeaGuide: FC = () => {
  return (
    <>
      <p>
        <span>Narrow Sea</span> is a multiplayer board game. In each turn the player places a tile on one of the
        indicated possible locations.
      </p>

      <p>
        Then she can optionally occupy a piece of city, land or sea by placing a meeple or a ship on it. Meeples can go
        on cities or land, ships can go on sea.
      </p>

      <img src={seaGuide} alt="Narrow Sea guide" />

      <p>
        <span>Cities</span> - 2 points / tile when completed, otherwise 1 point at the end of game.
      </p>

      <p>
        <span>Islands</span> - 1 point / tile when completed or at the end of game.
      </p>

      <p>
        <span>Sea</span> - 2 point / tile when completed, otherwise 1 point at the end of game.
      </p>

      <p>
        A sea also ends at a strait - <span>straits are marked with a light blue dashed line</span>.
        <br /> Also in the case of a strait there are 2 possible positions on both sides of the strait.
      </p>

      <h3>Sea Mini tiles</h3>
      <div className={classes.tileList}>
        {ccsonTileCount.seamini.map((tc) => (
          <GuideTileItem key={tc} tc={tc} family={'sea'} />
        ))}
      </div>

      <h3>Sea tiles</h3>
      <div className={classes.tileList}>
        {ccsonTileCount.sea.map((tc) => (
          <GuideTileItem key={tc} tc={tc} family={'sea'} />
        ))}
      </div>

      <h3>Sea Exp1 tiles</h3>
      <div className={classes.tileList}>
        {ccsonTileCount.seaexp1.map((tc) => (
          <GuideTileItem key={tc} tc={tc} family={'sea'} />
        ))}
      </div>

      <p>
        <span>Lighthouses</span> - double the score of the island - 2 points/tile if completed, 0 otherwise.
      </p>
      <p>
        <span>City with inner citadel</span> - scores 3 points/tile if completed, 0 otherwise.
      </p>

      <h3>Sea Exp2 tiles and rules</h3>
      <p>
        <span>Sea Exp2 - starter tiles</span>
      </p>
      <div className={classes.tileList}>
        {ccsonTileCount.seaexp2_starter.map((tc) => (
          <GuideTileItem key={tc} tc={tc} family={'sea'} />
        ))}
      </div>
      <p>Sea Exp 2 starts with 25 random tiles already placed - these are a random combination of the tiles above.</p>

      <p>
        <span>Sea Exp2 - etxre tiles and rules</span>
      </p>
      <div className={classes.tileList}>
        {ccsonTileCount.seaexp2_game.map((tc) => (
          <GuideTileItem key={tc} tc={tc} family={'sea'} />
        ))}
      </div>
      <p>
        <span>The whale </span> - you cannot place a ship on or around a whale so if you place a whale you will keep
        away ships from that area.
      </p>
      <p>
        <span>Treasure island</span> - you don&apos;t need to place a meeple on it - this simply add 6 points to the
        completed sea score. So teh winner of the sea will get the plus points for it.
      </p>
      <p>
        <span>Triangle shaped island</span> - this is just for separating seas.
      </p>
      <p>
        <span>Citadels with cannons</span> - if you complete a citadel with cannon you receive an extra (pirate) ship
        placed in front of each cannon. This extra ship can be used to conquer only that sea - when completed the extra
        ships vanishes.
      </p>
    </>
  );
};

export default SeaGuide;
