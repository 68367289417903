import classNames from 'classnames';
import React, { FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import WsConnection from '@envclient/envcore/src/service/WsConnection';
import Button from '@envclient/envcore/src/components/Button';
import classes from './Chat.module.scss';
import { RootState } from '@envclient/envcore/src/reducers/store';
import { ChatEntry, UserDTO } from '@envclient/envcore/src/types/core';
import { guideActions } from '../reducers/guideReducer';

const Chat: FC<Props> = ({ roomId, showChat, toggleChat, isPrivate }) => {
  const user = useSelector((state: RootState) => state.user) as UserDTO;
  const lobbyChatHistory = useSelector((state: RootState) => state.lobby.chatHistory);
  const gameChatHistory = useSelector((state: RootState) => state.game.chatHistory);
  const chatHistory = roomId === 0 ? lobbyChatHistory : gameChatHistory;
  const [msg, setMsg] = useState('');
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const dispatch = useDispatch();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMsg(e.target.value);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  const handleSend = () => {
    if (!msg || msg.length === 0) return;
    const isDevMessage = msg.substr(0, 1) === '#' && ['admin', 'bot'].includes(user.type);
    const newEntry: ChatEntry = {
      userId: user?.userId,
      displayName: user.displayName,
      message: isDevMessage ? msg.substr(1) : msg,
      type: isDevMessage ? 'DEV' : 'USER'
    };

    WsConnection.send({ type: 'CHAT', roomId, chatEntry: newEntry });
    setMsg('');
  };

  const handleClose = () => {
    if (toggleChat) toggleChat();
  };

  const showGuide = () => {
    dispatch(guideActions.showGuide());
  };

  const contClasses = classNames(classes.mainCont, { [classes.show]: showChat });
  const closeBtnClasses = classNames(classes.closeBtn, { [classes.showClose]: isPrivate });
  const guideBtnClasses = classNames(classes.guideBtn, { [classes.hideGuide]: isPrivate });

  return (
    <div className={contClasses}>
      <div className={classes.header}>
        <div className={classes.title}>Chat</div>
        <div className={guideBtnClasses}>
          <Button secondary toggled onClick={showGuide}>
            Guide
          </Button>
        </div>
        <div className={closeBtnClasses}>
          <Button secondary onClick={handleClose}>
            <FaTimes />
          </Button>
        </div>
      </div>
      <div className={classes.entries}>
        {chatHistory.map((entry, i) => {
          const devMsgClass = classNames({ [classes.devMessage]: entry.type === 'DEV' });
          const displayName = entry.type === 'DEV' ? 'DEV' : entry.displayName;
          return (
            <div key={i}>
              <span className={classes.displayName}>{displayName}</span> :{' '}
              <span className={devMsgClass}>{entry.message}</span>
            </div>
          );
        })}
        <div style={{ width: 1, height: 1 }} ref={messagesEndRef} />
      </div>
      <div className={classes.inputCont}>
        <input type="text" onChange={handleChange} value={msg} onKeyPress={handleKeyPress} />
        <Button onClick={handleSend}>Send</Button>
      </div>
    </div>
  );
};

interface Props {
  roomId: number;
  showChat: boolean;
  toggleChat?: () => void;
  isPrivate?: boolean;
}
export default Chat;
