import { TileDTO } from '../../types/ccson_env';
import { getTileType } from '../../config/tileMapConfig';
import React from 'react';
import TileItem from './TileItem';
import { TileBasedGameFamilyType } from '../../config/tileGameConfig';

const createTileItem = (tileType: number, family: TileBasedGameFamilyType) => {
  const tileDef = getTileType(tileType, family);
  const tileDTO: TileDTO = {
    id: 0,
    type: tileDef.type,
    x: 0,
    y: 0,
    rot: 0
  };
  return <TileItem family={family} tile={tileDTO} active={false} />;
};

export default createTileItem;
