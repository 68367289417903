import Modal from './Modal';
import { ClosableCompProps } from '@envclient/envcore/src/components/types';
import React, { FC, useEffect, useState } from 'react';
import classes from './BuyDialog.module.scss';
import Button from '@envclient/envcore/src/components/Button';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { RootState, useAppSelector } from '../reducers/store';
import { UserDTO } from '../types/core';
import Alert from './Alert';
import { getUserInfo } from '../service/userService';
/**
 * Paypal buy dialog. Displays two Paypal buttons.
 */

const creditArr = [
  { credits: 10, value: 1.2 },
  { credits: 50, value: 4.5 },
  { credits: 100, value: 9 },
  { credits: 200, value: 18 }
];

const BuyDialogPaypal: FC<ClosableCompProps> = ({ onClose }) => {
  const [credits, setCredits] = useState<number>(10);
  const [amount, setAmount] = useState<number>(1);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

  const myUser = useAppSelector((state: RootState) => state.user) as UserDTO;

  const handleClose = async () => {
    getUserInfo().then(onClose);
  };

  const handleBuyResult = (success: boolean) => {
    if (success) setShowAlert(true);
    else setShowErrorAlert(true);
  };

  const handleChange = (cr: number) => {
    setCredits(cr);
    const item = creditArr.find((it) => it.credits === cr) as { credits: number; value: number };
    setAmount(item?.value);
  };

  return (
    <>
      {showAlert && (
        <Alert
          title="Successful purchase"
          message="Thank you for buying credits! Your credits will appear shortly - please reload the page!"
          onClose={() => {
            setShowAlert(false);
            handleClose();
          }}
        />
      )}
      {showErrorAlert && (
        <Alert
          title="Purchase error"
          message="Purchase failed!"
          onClose={() => {
            setShowErrorAlert(false);
            handleClose();
          }}
        />
      )}
      <Modal>
        <div className={classes.mainCont}>
          <div className={classes.bg}></div>
          <div className={classes.panel}>
            <div className={classes.header}>
              <div className={classes.title}>Buy credits with Paypal</div>
            </div>
            <div className={classes.body}>
              <p className={classes.text}>
                Please select the amount of credits you wish to buy and click one of the buttons to check out with
                Paypal or bank card.
              </p>
              <div className={classes.optionRow}>
                <label htmlFor="amount">Amount</label>
                <select
                  name="amount"
                  id="amount"
                  value={credits}
                  onChange={(e) => handleChange(Number(e.target.value))}>
                  {creditArr.map((item) => {
                    return (
                      <option key={item.credits} value={item.credits}>
                        {`${item.credits} credits $${item.value} USD`}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className={classes.optionRow}></div>

              <PayPalScriptProvider
                options={{
                  'client-id': window.runtimeConfig.PAYPAL_CLIENT_ID,
                  components: 'buttons',
                  currency: 'USD'
                }}>
                <ButtonWrapper
                  currency={'USD'}
                  amount={String(amount)}
                  showSpinner={false}
                  credits={String(credits)}
                  userId={myUser.userId}
                  onClose={handleBuyResult}
                />
              </PayPalScriptProvider>
            </div>
            <div className={classes.btnBar}>
              <Button onClick={handleClose}>Cancel</Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const style = { layout: 'vertical' as const };

// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({
  currency,
  showSpinner,
  amount,
  credits,
  userId,
  onClose
}: {
  currency: string;
  amount: string;
  showSpinner: boolean;
  credits: string;
  userId: number;
  onClose: (success: boolean) => void;
}) => {
  // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
  // This is the main reason to wrap the PayPalButtons in a new component
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: 'resetOptions',
      value: {
        ...options,
        currency
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, showSpinner]);

  return (
    <>
      {showSpinner && isPending && <div className="spinner" />}
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[amount, currency, style, userId]}
        fundingSource={undefined}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  description: `${credits} credits`,
                  amount: {
                    currency_code: currency,
                    value: amount
                  },
                  custom_id: String(userId)
                }
              ]
            })
            .then((orderId) => {
              // Your code here after create the order
              return orderId;
            });
        }}
        onApprove={function (data, actions) {
          return actions.order
            ? actions.order.capture().then(function (data) {
                console.log('Paypal buy success', data);
                onClose(true);
              })
            : Promise.resolve();
        }}
        onError={function (error) {
          console.error('Paypal buy error', error);
          onClose(false);
        }}
      />
    </>
  );
};

export default BuyDialogPaypal;
