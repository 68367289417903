import { KoenigsburgTileDTO } from '../../types/koenigsburg_env';
import KTileItem from './KTileItem';
import React from 'react';
import classes from './OverlayCont.module.scss';

interface OverlayContProps {
  overlays: { [key: string]: KoenigsburgTileDTO[] };
}

const ITEM_WIDTH = 260;

const OverlayCont = (props: OverlayContProps) => {
  return (
    <div className={classes.overlayCont}>
      {Object.values(props.overlays).map((e, index) => {
        const tile = e.length ? e[0] : null;
        if (!tile) return null;
        const divStyle = {
          top: 0 + 'px',
          left: index * ITEM_WIDTH + 'px'
        };
        return (
          <>
            <div className={classes.tileBg} key={tile.type} style={divStyle}>
              <KTileItem active={true} tile={tile} />
            </div>
            <div className={classes.count} style={divStyle}>
              {e.length}
            </div>
          </>
        );
      })}
    </div>
  );
};

export default OverlayCont;
