import { Reducer } from 'redux';
import { ChatEntry, UserDTO } from '../types/core';

const SET_LOBBY_USER_LIST = 'SET_LOBBY_USER_LIST';
const JOIN_LOBBY = 'JOIN_LOBBY';
const LEAVE_LOBBY = 'LEAVE_LOBBY';
const LOBBY_CHAT_HISTORY = 'LOBBY_CHAT_HISTORY';
const LOBBY_CHAT_MESSAGE = 'LOBBY_CHAT_MESSAGE';

export const setLobbyUserList = (userList: UserDTO[]) => ({ type: SET_LOBBY_USER_LIST, userList });
export const leaveLobby = (user: UserDTO) => ({ type: LEAVE_LOBBY, user });
export const joinLobby = (user: UserDTO) => ({ type: JOIN_LOBBY, user });
export const lobbyChatHistory = (history: ChatEntry[]) => ({ type: LOBBY_CHAT_HISTORY, history });
export const lobbyChatMessage = (entry: ChatEntry) => ({ type: LOBBY_CHAT_MESSAGE, entry });

export interface LobbyData {
  userList: UserDTO[];
  chatHistory: ChatEntry[];
}
const initialState: LobbyData = {
  userList: [],
  chatHistory: []
};

function addUser(userList: UserDTO[], user: UserDTO) {
  const userListCopy = userList.concat();
  if (!userList.find((value) => value.userId === user.userId)) {
    userListCopy.push(user);
  }
  return userListCopy;
}

function removeUser(userList: UserDTO[], user: UserDTO) {
  const index = userList.findIndex((value) => value.userId === user.userId);
  return userList.slice(0, index).concat(userList.slice(index + 1));
}

const lobbyReducer: Reducer<LobbyData> = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOBBY_USER_LIST:
      return Object.assign({}, state, { userList: action.userList });
    case JOIN_LOBBY:
      return Object.assign({}, state, { userList: addUser(state.userList, action.user) });
    case LEAVE_LOBBY:
      return Object.assign({}, state, { userList: removeUser(state.userList, action.user) });
    case LOBBY_CHAT_HISTORY:
      return Object.assign({}, state, { chatHistory: action.history });
    case LOBBY_CHAT_MESSAGE:
      return Object.assign({}, state, { chatHistory: state.chatHistory.concat(action.entry) });
    default:
      return state;
  }
};

export default lobbyReducer;
