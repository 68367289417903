import React, { ReactElement } from 'react';
import classes from './KNode.module.scss';
import { NodeDef } from '../../config/tileMapConfig';

interface NodeProps {
  node: NodeDef;
  active: boolean;
  tileWidth: number;
  tileHeight: number;
}

const KNode = (props: NodeProps): ReactElement => {
  const { active, node, tileWidth, tileHeight } = props;
  const { x, y } = node;
  const nodeStyle = {
    left: x * tileWidth,
    top: y * tileHeight,
    display: 'block'
  };

  // hide nodes on inactive tiles
  if (!active) {
    nodeStyle.display = 'none';
  }
  return <div className={classes.node} style={nodeStyle} />;
};

export default KNode;
