import React, { FC, useState } from 'react';
import { FaCoins, FaSignOutAlt, FaDollarSign } from 'react-icons/fa';
import classes from './AppHeader.module.scss';
import Button from '@envclient/envcore/src/components/Button';
import WsConnection from '@envclient/envcore/src/service/WsConnection';
import BuyDialogPaypal from './BuyDialogPaypal';
import BuyDialogApp from './BuyDialogApp';
import { useAppSelector } from '../reducers/store';
import ConvertPointsDialog from './ConvertPointsDialog';
import BuyDialogFacebookRedirect from './BuyDialogFacebookRedirect';

const AppHeader: FC = () => {
  const [buyOpen, setBuyOpen] = useState<boolean>(false);
  const [buyPpOpen, setBuyPpOpen] = useState<boolean>(false);
  const [buyAppOpen, setBuyAppOpen] = useState<boolean>(false);
  const [convertOpen, setConvertOpen] = useState<boolean>(false);
  const user = useAppSelector((state) => state.user);

  const handleDisconnect = () => {
    WsConnection.disconnect();
  };

  const handleCredits = () => {
    if (window.runtimeConfig.IS_MOBILE) {
      setBuyAppOpen(true);
    } else if (window.top !== window.self) {
      console.log('Fb frame', window.top, window.self);
      // inside Facebook app iframe
      setBuyOpen(true);
      return;
    } else {
      setBuyPpOpen(true);
    }
  };

  return (
    <div className={classes.mainCont}>
      <div className={classes.title}>Seven Castles</div>

      <div className={classes.flex}>
        <Button onClick={() => setConvertOpen(true)} secondary>
          <FaCoins />
          Points {user?.points}
        </Button>

        <Button onClick={handleCredits} secondary>
          <FaDollarSign />
          Credits {user?.credits}
        </Button>

        <Button onClick={handleDisconnect} secondary>
          <FaSignOutAlt />
          <span className={classes.disconnect}>Disconnect</span>
        </Button>
      </div>
      {convertOpen && <ConvertPointsDialog onClose={() => setConvertOpen(false)} />}
      {buyOpen && <BuyDialogFacebookRedirect onClose={() => setBuyOpen(false)} />}
      {buyPpOpen && <BuyDialogPaypal onClose={() => setBuyPpOpen(false)} />}
      {buyAppOpen && <BuyDialogApp onClose={() => setBuyAppOpen(false)} />}
    </div>
  );
};

export default AppHeader;
