import { EnvTypeDTO, TileGameConfig } from '../types/ccson_env';
import { ccsonGameConfigs, koenigsburgGameConfigs, TileBasedGameFamilyType } from './tileGameConfig';

export interface GameFamily {
  name: string;
  type: TileBasedGameFamilyType;
  envType: EnvTypeDTO;
  src: string;
  title: string;
  games: TileGameConfig[];
}

const gameFamilies: GameFamily[] = [
  {
    type: 'ccson',
    envType: 'ccson',
    src: 'ccson_page_cover.png',
    title: 'Carcassonne',
    name: 'carcassonne',
    games: ccsonGameConfigs.filter((gt) => gt.family === 'ccson').filter((g) => g.type !== 'fixed')
  },
  {
    type: 'sea',
    envType: 'ccson',
    src: 'sea_page_cover.png',
    title: 'Narrow Sea',
    name: 'narrowsea',
    games: ccsonGameConfigs.filter((gt) => gt.family === 'sea')
  },
  {
    type: 'castle',
    envType: 'ccson',
    src: 'castle-tiles.png',
    title: 'Seven Castles',
    name: 'castles',
    games: ccsonGameConfigs.filter((gt) => gt.family === 'castle')
  }
];

if (window.location.hostname === 'localhost') {
  gameFamilies.push({
    type: 'koenigsburg',
    envType: 'koenigsburg',
    src: 'castle-tiles.png',
    title: 'Koenigsbourg',
    name: 'koenigsburg',
    games: koenigsburgGameConfigs
  });
}

export { gameFamilies };
