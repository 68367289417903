import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, NavLink } from 'react-router-dom';
import { getUserInfo } from '@envclient/envcore/src/service/userService';
import classes from './HomeView.module.scss';
import Button from '@envclient/envcore/src/components/Button';
import LoginDialog from './LoginDialog';
import { history, RootState } from '@envclient/envcore/src/reducers/store';
import { setFirstConnect } from '@envclient/envcore/src/reducers/firstConnectReducer';
import WsConnection from '../service/WsConnection';

const HomeView: FC<{ PageCover: FC; title: string }> = ({ PageCover, title }) => {
  const dispatch = useDispatch();
  const firstConnect = useSelector((state: RootState) => state.firstConnect);

  const loadUserAndConnect = () => {
    getUserInfo()
      .then(() => {
        WsConnection.connect();
      })
      .catch(() => {
        history.push('/login');
      });
  };

  // auto connect
  useEffect(() => {
    dispatch(setFirstConnect(false));
    if (firstConnect) {
      loadUserAndConnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstConnect]);

  const handleConnect = () => {
    //  load userinfo then connect, otherwise show login dialog
    loadUserAndConnect();
  };

  const handleLogin = () => {
    history.push('/login');
  };

  return (
    <div className={classes.mainCont}>
      <Route exact path="/login">
        <LoginDialog />
      </Route>
      <h1 className={classes.title}>{title}</h1>
      <span>Carcassonne-style board games</span>
      <PageCover />
      <div className={classes.btnBar}>
        <Button onClick={handleConnect}>Connect</Button>
        <Button onClick={handleLogin}>Login</Button>
      </div>
      <div className={classes.btnBar}>
        <NavLink to="/privacypolicy">Privacy policy</NavLink>
      </div>
    </div>
  );
};
export default HomeView;
