import { Reducer } from 'redux';
import { ChatEntry } from '../types/core';

const GAME_CHAT_HISTORY = 'GAME_CHAT_HISTORY';
const GAME_CHAT_MESSAGE = 'GAME_CHAT_MESSAGE';

export const gameChatHistory = (history: ChatEntry[]) => ({ type: GAME_CHAT_HISTORY, history });
export const gameChatMessage = (entry: ChatEntry) => ({ type: GAME_CHAT_MESSAGE, entry });

const initialState: ChatEntry[] = [];

const gameChatReducer: Reducer<ChatEntry[]> = (state = initialState, action) => {
  switch (action.type) {
    case GAME_CHAT_HISTORY:
      return action.history;
    case GAME_CHAT_MESSAGE:
      return state.concat(action.entry);
    default:
      return state;
  }
};

export default gameChatReducer;
