import { PlayerItemProps } from '@envclient/envcore/src/components/PlayerList';
import { RootState } from '@envclient/envcore/src/reducers/store';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { CcsonEnvDTO, CcsonGameTypeDTO, CcsonPlayerDTO } from '../../types/ccson_env';
import classes from './CcsonPlayerItem.module.scss';

export const colorMap = {
  0: { name: 'blue', code: '#01A4CC' },
  1: { name: 'red', code: '#E22D5A' },
  2: { name: 'yellow', code: '#FCEB00' },
  3: { name: 'orange', code: '#FF8C00' },
  4: { name: 'grey', code: '#AAAAAA' }
};

/**
 * Ccson specific PlayerItem impl.
 */

const CcsonPlayerItem: FC<CcsonPlayerItemProps> = (props) => {
  const { player, timeLeft, actPlayer, gameStatus } = props;
  const isActive = actPlayer ? player.id === actPlayer.id : false;
  const playerColor = colorMap[player.color].code;
  const playerStyle = { color: playerColor };

  const env = useSelector<RootState>((state) => state.game.env) as CcsonEnvDTO;
  const isExp2 = (env.gameType as CcsonGameTypeDTO).split('|').includes('exp2');

  return (
    <div key={player.id} className={classes.player} style={playerStyle}>
      <div className={classes.displayName}>{player.displayName} </div>
      <div className={classes.score}>{player.score} points</div>
      <div className={classes.active}>{isActive && gameStatus === 'PLAYING' && timeLeft}</div>
      {player.inactiveCount > 0 && <div className={classes.misses}>{player.inactiveCount} missed</div>}
      {isExp2 && (
        <>
          <div className={classes.tradeIcon + ' ' + classes.tradeWine} />
          <div className={classes.tradeLabel}> x {player.trade['wine']}</div>
          <div className={classes.tradeIcon + ' ' + classes.tradeGrain} />
          <div className={classes.tradeLabel}> x {player.trade['grain']}</div>
          <div className={classes.tradeIcon + ' ' + classes.tradeCloth} />
          <div className={classes.tradeLabel}> x {player.trade['cloth']}</div>
        </>
      )}
    </div>
  );
};

interface CcsonPlayerItemProps extends PlayerItemProps {
  player: CcsonPlayerDTO;
  actPlayer: CcsonPlayerDTO;
  myPlayer: CcsonPlayerDTO;
  players: CcsonPlayerDTO[];
}

export default CcsonPlayerItem;
