import { UserDTO } from '@envclient/envcore/src/types/core';
import React, { FC, useState } from 'react';
import Button from '@envclient/envcore/src/components/Button';
import classes from './LobbyUserList.module.scss';
import { useInterval } from 'react-use';

const LobbyUser = ({
  user,
  myUser,
  onInvite
}: {
  user: UserDTO;
  myUser: UserDTO;
  onInvite: (user: UserDTO) => void;
}) => {
  const [invited, setInvited] = useState(false);
  const isMe = user.userId === myUser?.userId;

  const REENABLE_INVITE_TIMEOUT = 30000;

  useInterval(
    () => {
      setInvited(false);
    },
    invited ? REENABLE_INVITE_TIMEOUT : null
  );

  return (
    <li className={classes.listItem} key={user.userId}>
      <div className={classes.listCont}>
        <span className={classes.gameCount}>{user.gameCount}</span>
        <span className={classes.displayName}>{user.displayName}</span>
        {!isMe && (
          <Button
            secondary
            disabled={invited}
            onClick={() => {
              setInvited(true);
              onInvite(user);
            }}>
            Invite
          </Button>
        )}
      </div>
    </li>
  );
};

const LobbyUserList: FC<Props> = ({ userList, onInvite, myUser }) => {
  return (
    <ul className={classes.list}>
      {userList.map((user) => (
        <LobbyUser key={user.userId} user={user} myUser={myUser} onInvite={onInvite} />
      ))}
    </ul>
  );
};

interface Props {
  userList: UserDTO[];
  onInvite: (user: UserDTO) => void;
  myUser: UserDTO;
}

export default LobbyUserList;
