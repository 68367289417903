import { Reducer } from 'redux';
import { GameDTO } from '../types/core';
import { AppStatus } from './appStatusReducer';
import { UPDATE_GAME } from './gameActions';

const initialState = null;

const gameDataReducer: Reducer<GameDTO | null> = (state = initialState, action) => {
  if (action.type === UPDATE_GAME) {
    return action.game;
  } else if (action.payload === AppStatus.LOBBY) {
    return initialState;
  }
  return state;
};

export default gameDataReducer;
