import React, { FC, useEffect } from 'react';
import classes from './KGameCanvas.module.scss';
import { GameCanvasProps } from '@envclient/envcore/src/components/GameView';
import { KActionDTO, KoenigsburgEnvDTO } from '../../types/koenigsburg_env';
import KTileMap from './KTileMap';

/**
 * Kgsburg game comps: ktilemap
 */
const KoenigsburgGameCanvas: FC<GameCanvasProps> = ({ env, selectedAction, active, game, handleActionChange }) => {
  const { tiles, currentActions, tilesLeft } = env as KoenigsburgEnvDTO;
  const startBtnVisible = game.status === 'IDLE';

  const onActionChange = (action: KActionDTO) => {
    handleActionChange(action);
  };

  return (
    <>
      {tiles && (
        <KTileMap
          tileData={tiles}
          currentActions={currentActions}
          selectedAction={selectedAction as KActionDTO}
          onActionChange={onActionChange}
          active={active}
        />
      )}
      <div className={classes.tilesLeft}>{!startBtnVisible && `Tiles: ${tilesLeft}`}</div>
    </>
  );
};

export default KoenigsburgGameCanvas;
