import { CcsonFamilyTypeDTO } from '../../types/ccson_env';
import { Pos } from '@envclient/envcore/src/types/frontendTypes';
import { KoenigsburgFamilyTypeDTO } from '../../types/koenigsburg_env';
import { TileBasedGameFamilyType } from '../../config/tileGameConfig';

const ccsonTileSize = 150;

function ccsonTileCoordsByIndex(i: number) {
  const res = { x: 0, y: 0 };

  // expansion 2 tiles - are shifted on spritesheet
  if (i >= 90 && i <= 113) {
    i = i - 42;
  }

  res.x = (i % 8) * ccsonTileSize;
  res.y = Math.floor(i / 8) * ccsonTileSize;

  return res;
}

function castlesTileCoordsByIndex(i: number) {
  const mapping: Record<number, Pos> = {
    //river+cloister
    41: { x: 0, y: 0 },
    42: { x: 1, y: 0 },
    43: { x: 2, y: 0 },
    44: { x: 3, y: 0 },
    45: { x: 4, y: 0 },
    46: { x: 5, y: 0 },

    //cloister
    47: { x: 6, y: 0 },

    //castle only
    51: { x: 0, y: 1 },
    52: { x: 1, y: 1 },
    53: { x: 2, y: 1 },

    //river + castle
    60: { x: 3, y: 1 },
    61: { x: 3, y: 4 },
    62: { x: 5, y: 1 },
    63: { x: 6, y: 1 },
    65: { x: 1, y: 2 },
    66: { x: 2, y: 2 },
    67: { x: 3, y: 2 },

    69: { x: 5, y: 2 },
    73: { x: 1, y: 3 },
    74: { x: 2, y: 3 },
    75: { x: 3, y: 3 },
    76: { x: 4, y: 3 },

    // plus tiles
    72: { x: 0, y: 3 },
    78: { x: 6, y: 3 },
    80: { x: 0, y: 4 },
    81: { x: 1, y: 4 },
    82: { x: 2, y: 4 },
    83: { x: 4, y: 1 }
  };

  const res = {
    x: mapping[i].x * ccsonTileSize,
    y: mapping[i].y * ccsonTileSize
  };

  return res;
}

function seaTileCoordsByIndex(i: number) {
  const mapping: Record<number, Pos> = {
    //sea
    120: { x: 0, y: 0 },

    //citadel + sea/land
    121: { x: 0, y: 1 },
    122: { x: 1, y: 1 },
    123: { x: 0, y: 2 },
    124: { x: 1, y: 2 },
    125: { x: 2, y: 2 },
    126: { x: 3, y: 2 },
    127: { x: 0, y: 3 },
    128: { x: 1, y: 3 },
    129: { x: 2, y: 3 },
    130: { x: 1, y: 4 }, // use 131
    131: { x: 1, y: 4 },
    132: { x: 2, y: 4 },
    133: { x: 3, y: 4 },
    146: { x: 4, y: 4 },
    147: { x: 5, y: 4 },
    134: { x: 0, y: 5 },
    135: { x: 1, y: 5 },
    136: { x: 2, y: 5 },

    // inner citadel,
    137: { x: 0, y: 6 },
    138: { x: 2, y: 6 }, // use 138_alt

    // land+sea
    139: { x: 0, y: 7 },
    140: { x: 1, y: 7 },
    141: { x: 2, y: 7 },
    142: { x: 3, y: 7 },
    143: { x: 3, y: 8 }, // use 143_alt
    144: { x: 1, y: 8 },
    145: { x: 2, y: 8 },

    // sea separators, lighthouses
    148: { x: 0, y: 9 },
    155: { x: 1, y: 9 },
    156: { x: 2, y: 9 },
    157: { x: 3, y: 9 },
    158: { x: 4, y: 9 },

    //citadel separators
    150: { x: 0, y: 10 },
    151: { x: 1, y: 10 },
    152: { x: 2, y: 10 },

    //treasure island
    149: { x: 0, y: 11 },

    // new sea separators
    160: { x: 1, y: 11 },
    161: { x: 2, y: 11 },
    162: { x: 3, y: 11 },

    // whale
    163: { x: 0, y: 12 },

    // city + cannon
    164: { x: 0, y: 13 },
    165: { x: 1, y: 13 },
    166: { x: 2, y: 13 },
    167: { x: 3, y: 13 }
  };

  const res = {
    x: mapping[i].x * ccsonTileSize,
    y: mapping[i].y * ccsonTileSize
  };

  return res;
}

const hexaTileWidth = 130 * 2;
const hexaTileHeight = 150 * 2;
const hexaTileDX = 65 * 2;
const hexaTileDY = 113 * 2;

function koenigsburgTileCoordsByIndex(i: number) {
  const mapping: Record<number, Pos> = {
    // hexa coords!!!
    49: { x: 0, y: 0 },
    50: { x: 1, y: 0 },
    51: { x: 0, y: 1 },
    52: { x: 1, y: 1 },
    53: { x: 0, y: 2 },
    54: { x: 1, y: 2 },
    55: { x: 0, y: 3 },
    56: { x: 1, y: 3 },
    57: { x: 0, y: 4 },
    58: { x: 1, y: 4 },
    59: { x: 0, y: 5 },
    60: { x: 1, y: 5 },
    61: { x: 0, y: 6 },
    62: { x: 1, y: 6 },
    69: { x: 0, y: 7 },
    70: { x: 1, y: 7 },
    71: { x: 0, y: 8 },
    72: { x: 1, y: 8 },
    73: { x: 0, y: 9 },
    74: { x: 1, y: 9 },
    75: { x: 0, y: 10 },
    76: { x: 1, y: 10 },
    77: { x: 0, y: 11 }
  };

  return {
    x: mapping[i].x * hexaTileWidth + mapping[i].y * hexaTileDX,
    y: mapping[i].y * hexaTileDY
  };
}

function ccsonTileCoordsByFamily(family: CcsonFamilyTypeDTO, index: number) {
  if (family === 'ccson') {
    return ccsonTileCoordsByIndex(index);
  } else if (family === 'sea') {
    return seaTileCoordsByIndex(index);
  } else if (family === 'castle') {
    return castlesTileCoordsByIndex(index);
  }
}

function isKoenigsburgFamily(family: TileBasedGameFamilyType): boolean {
  return (family as KoenigsburgFamilyTypeDTO) == 'koenigsburg';
}

function koenigsburgTileCoordsByFamily(family: KoenigsburgFamilyTypeDTO, index: number) {
  if (family === 'koenigsburg') {
    return koenigsburgTileCoordsByIndex(index);
  }
}

const getTileStyle = (x: number, y: number, family: TileBasedGameFamilyType) => {
  if ((family as KoenigsburgFamilyTypeDTO) == 'koenigsburg') {
    return getHexaTileStyle(x, y);
  } else return getCcsonTileStyle(x, y);
};

const getCcsonTileStyle = (x: number, y: number) => {
  const left = x * ccsonTileSize + 'px';
  const top = y * ccsonTileSize + 'px';

  return { left, top };
};

const getHexaTileStyle = (x: number, y: number) => {
  const left = x * hexaTileWidth + y * hexaTileDX + 'px';
  const top = y * hexaTileDY + 'px';

  return { left, top };
};

const getTileCoords = (type: number, family: TileBasedGameFamilyType) => {
  if ((family as KoenigsburgFamilyTypeDTO) == 'koenigsburg') {
    return koenigsburgTileCoordsByFamily(family as KoenigsburgFamilyTypeDTO, type);
  }
  return ccsonTileCoordsByFamily(family as CcsonFamilyTypeDTO, type);
};

const getCcsonContainerStyle = (coordinates: Pos, rotation: number) => {
  return {
    width: ccsonTileSize + 'px',
    height: ccsonTileSize + 'px',
    backgroundPosition: `-${coordinates.x}px -${coordinates.y}px`,
    transformOrigin: ccsonTileSize / 2 + 'px ' + ccsonTileSize / 2 + 'px',
    transform: 'rotate(' + rotation + 'deg)'
  };
};

const getHexaContainerStyle = (coordinates: Pos, rotation: number) => {
  return {
    width: hexaTileWidth + 'px',
    height: hexaTileHeight + 'px',
    backgroundPosition: `-${coordinates.x}px -${coordinates.y}px`,
    transformOrigin: hexaTileWidth / 2 + 'px ' + hexaTileHeight / 2 + 'px',
    transform: 'rotate(' + rotation + 'deg)'
  };
};

const getContainerStyle = (coordinates: Pos, rotation: number, family: TileBasedGameFamilyType) => {
  if ((family as KoenigsburgFamilyTypeDTO) == 'koenigsburg') {
    return getHexaContainerStyle(coordinates, rotation);
  } else return getCcsonContainerStyle(coordinates, rotation);
};

const getTileWidth = (family: TileBasedGameFamilyType) => {
  if ((family as KoenigsburgFamilyTypeDTO) == 'koenigsburg') return hexaTileWidth;
  return ccsonTileSize;
};

const getTileHeight = (family: TileBasedGameFamilyType) => {
  if ((family as KoenigsburgFamilyTypeDTO) == 'koenigsburg') return hexaTileHeight;
  return ccsonTileSize;
};

export { isKoenigsburgFamily, getTileStyle, getTileCoords, getContainerStyle, getTileWidth, getTileHeight };
