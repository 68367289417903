import { ccsonGameConfigs } from './tileGameConfig';

// set globally used, static values here, not in redux

// set appConfig
window.appConfig = {
  version: 4.2
};

// set ccson gameConfigs - this will be expanded later
window.gameConfigs = ccsonGameConfigs;

export {};
