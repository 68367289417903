import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const slice = createSlice({
  name: 'createGame',
  initialState,
  reducers: {
    showCreate: () => true,
    hideCreate: () => false
  },
  extraReducers: (/* builder */) => {
    // TODO hide creategame when join game
    //builder.addCase(appStatusActions.setGame, () => false )
  }
});

export const createGameActions = slice.actions;

export default slice.reducer;
