import React, { FC } from 'react';
import { gameFamilies, GameFamily } from '../../config/gameFamilyConfig';

import classes from './GameFamilies.module.scss';
/**
 * List of game family thumbnails (ccson, castles, sea, koenigsburg).
 */

interface GameFamiliesProps {
  onSelectFamily?: (family: GameFamily) => void;
  showTitle?: boolean;
}

const GameFamilies: FC<GameFamiliesProps> = ({ onSelectFamily, showTitle = true }) => {
  return (
    <div className={classes.mainCont}>
      {gameFamilies.map((gf) => (
        <div
          className={classes.familyItem}
          key={gf.type}
          onClick={() => {
            onSelectFamily?.(gf);
          }}>
          {showTitle && (
            <div className={classes.header}>
              <div
                className={classes.title}
                onClick={() => {
                  onSelectFamily?.(gf);
                }}>
                {gf.title}
              </div>
            </div>
          )}
          <img className={classes.thumbnail} src={'./static/public/media/' + gf.src} />
        </div>
      ))}
    </div>
  );
};

export default GameFamilies;
