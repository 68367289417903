import React, { FC, useEffect, useState } from 'react';
import TileItem, { calculateMeeplePos } from './TileItem';
import TilePlaceholder from './TilePlaceholder';
import classes from './TileMap.module.scss';
import Meeple from './Meeple';
import { CSSTransition } from 'react-transition-group';
import {
  CcsonEnvDTO,
  CcsonPlayerDTO,
  MeepleDTO,
  MeepleType,
  RecoveredMeeplesDTO,
  TileDTO
} from '../../types/ccson_env';
import { CSSPos, Pos } from '@envclient/envcore/src/types/frontendTypes';
import { cloneObject } from '@envclient/envcore/src/utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@envclient/envcore/src/reducers/store';
import { consumeGameEvent } from '@envclient/envcore/src/reducers/gameActions';
import { ActionDTO, BestActionDTO, GameEventDTO } from '@envclient/envcore';
import PrismaZoom from '@envclient/envcore/src/components/PrismaZoom';
import { TileBasedGameFamilyType } from '../../config/tileGameConfig';
import { getTileHeight, getTileWidth } from './TileAsset';
import TileAnchor from './TileAnchor';
import Button from '@envclient/envcore/src/components/Button';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { FiRotateCcw } from 'react-icons/fi';
import { useMyPlayer } from '@envclient/envcore/src/hooks/useMyPlayer';
import MeepleCont from './MeepleCont';
import { TileDef, getTileDef } from '../../config/tileMapConfig';
import { useInterval } from 'react-use';

export type Positions = Record<string, { coord: string; actions: TileDTO[]; x: number; y: number }>;

export type SelectPosActions<A extends ActionDTO = ActionDTO> = { selectedPos: Pos; posActions: A[] };

interface TileMapProps {
  tileData: Array<TileDTO>;
  currentActions: Array<TileDTO>;
  lastTile: TileDTO;
  onSubmit: (action?: TileDTO) => void;
  active: boolean;
}

const BEST_ACTION_TIMEOUT = 10000;

const TileMap: FC<TileMapProps> = (props) => {
  const { tileData, currentActions, lastTile, active, onSubmit } = props;

  const [submitted, setSubmitted] = useState<boolean>(false);
  const game = useSelector((state: RootState) => state.game.game);

  const submitBtnEnabled = active && game?.status === 'PLAYING' && !submitted;

  const [selectedAction, setSelectedAction] = useState<TileDTO | null>(null);
  const [bestAction, setBestAction] = useState<ActionDTO | null>(null);
  const [recoveredMeeplesEvent, setRecoveredMeeples] = useState<RecoveredMeeplesDTO | null>(null);
  const events = useSelector((state: RootState) => state.game.events) as unknown as GameEventDTO[];
  const env = useSelector((state: RootState) => state.game.env) as CcsonEnvDTO;
  const family = env.gameConfig.family as TileBasedGameFamilyType;
  const dispatch = useDispatch();
  const [positions, setPositions] = useState<Positions>({});

  const myPlayer = useMyPlayer();
  const meeples = (myPlayer as CcsonPlayerDTO)?.meeples;
  const [preferredMeepleType, setPreferredMeepleType] = useState<MeepleType>();

  const [selectedPos, setSelectedPos] = useState<Pos | null>(null);
  const [posActions, setPosActions] = useState<TileDTO[] | null>(null);

  const [zoom, setZoom] = useState<number>(0.5);

  const tileWidth = getTileWidth(family);
  const tileHeight = getTileHeight(family);

  useEffect(
    function currentActionsChanged() {
      // clear state
      setSelectedAction(null);
      setBestAction(null);
      setSelectedPos(null);
      setSubmitted(false);
      // group actions by position
      const positions: Positions = {};
      currentActions.forEach((a) => {
        if (a.x !== null || a.y !== null) {
          const coord = a.x + ':' + a.y;
          if (!positions[coord]) {
            positions[coord] = { coord, actions: [], x: a.x, y: a.y };
          }
          positions[coord].actions.push(a);
        }
      });
      setPositions(positions);
    },
    [currentActions]
  );

  const tilePlaces = Object.keys(positions).map((k) => positions[k]);

  // why???
  const selectedTilePos = cloneObject(selectedAction);
  if (selectedTilePos && selectedPos) {
    selectedTilePos.x = selectedPos.x;
    selectedTilePos.y = selectedPos.y;
  }

  //reset state when action changes
  useEffect(() => {
    if (selectedAction === null) {
      setSelectedPos(null);
      setPosActions(null);
    }
  }, [selectedAction, positions]);

  useEffect(
    function processEvents() {
      if (events?.length === 0) return;
      // RECOVERED_MEEPLES
      // @ts-expect-error type
      const meeplesEvent = events.filter((e) => e.type === 'RECOVERED_MEEPLES')[0] as unknown as RecoveredMeeplesDTO;
      if (meeplesEvent) {
        setRecoveredMeeples(meeplesEvent);
        dispatch(consumeGameEvent(meeplesEvent as unknown as GameEventDTO));
      }
      // BEST_ACTION
      const bestActionEvent = events.filter((e) => e.type === 'BEST_ACTION')[0] as BestActionDTO;
      if (bestActionEvent) {
        const action = bestActionEvent.bestAction;
        const newSelectedAction = action as TileDTO;
        setSelectedAction(newSelectedAction);
        dispatch(consumeGameEvent(bestActionEvent));
        if (!active || submitted) return;
        if (!currentActions.find((a) => a.id === newSelectedAction.id)) return;
        setBestAction(action);
        const selectedPos = { x: newSelectedAction.x, y: newSelectedAction.y };
        setSelectedPos(selectedPos);
        if (positions && positions[newSelectedAction.x + ':' + newSelectedAction.y]) {
          setPosActions(positions[newSelectedAction.x + ':' + newSelectedAction.y].actions);
        }
      }
    },
    [events, dispatch, positions]
  );

  const placeholderClick = ({ selectedPos, posActions }: SelectPosActions) => {
    const defaultAction = posActions[0];
    setSelectedAction(defaultAction as TileDTO);
    setSelectedPos(selectedPos);
    setPosActions(posActions as TileDTO[]);
  };

  const lastTileClick = (selectedAction: TileDTO) => {
    setSelectedAction(selectedAction);
  };

  const lastMeepleClick = (meeple: MeepleDTO) => {
    if (!posActions?.length) return;
    const defaultAction = posActions[0];
    setSelectedAction(defaultAction as TileDTO);
    // TODO play send back animation
    console.log('meepleClick', meeple);
  };

  const handleZoomChange = (value: number) => {
    setZoom(value);
  };

  const handleZoomIn = () => {
    setZoom(zoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom(zoom - 0.1);
  };

  const handleRotate = () => {
    if (!posActions || !selectedAction) return;
    // try to rotate forw
    let nextRotPos = posActions.find((action: TileDTO) => action.rot > selectedAction.rot);
    //try to rotate backw
    if (!nextRotPos) nextRotPos = posActions.find((action: TileDTO) => action.rot < selectedAction.rot);
    if (nextRotPos) {
      setSelectedAction(nextRotPos);
    }
  };

  const handleMeepleTypeChange = (type: MeepleType | undefined) => {
    setPreferredMeepleType(type);
  };

  const handleSubmit = () => {
    if (!active) return;
    if (!selectedAction) return;
    if (submitted) return;
    onSubmit(selectedAction);
    setBestAction(null);
    setSubmitted(true);
  };

  const handleSkip = () => {
    if (!active) return;
    if (submitted) return;
    onSubmit(undefined);
    setSelectedAction(null);
    setBestAction(null);
  };

  useInterval(
    function submitBestAction() {
      handleSubmit();
      setBestAction(null);
    },
    bestAction ? BEST_ACTION_TIMEOUT : null
  );

  if (!tileData) return null;

  return (
    <div className={classes.tileMap}>
      <PrismaZoom scrollVelocity={0.05} currentZoom={zoom} onZoomChange={handleZoomChange}>
        <div className={classes.canvas}>
          <div className={classes.bg}></div>
          {tileData.map((t, index) => {
            return <TileItem family={family} active={false} key={t.id} tile={t} last={index === tileData.length - 1} />;
          })}
          {lastTile &&
            active &&
            tilePlaces.map((p) => (
              <TilePlaceholder
                key={p.coord + '_' + lastTile.id}
                x={p.x}
                y={p.y}
                actions={p.actions}
                onClick={placeholderClick}
                family={family}
              />
            ))}
          {lastTile && active && selectedTilePos && posActions && (
            <TileItem
              family={family}
              key={lastTile.id}
              active={true}
              tile={selectedTilePos}
              posActions={posActions}
              onClick={lastTileClick}
              onMeepleClick={lastMeepleClick}
              preferredMeepleType={preferredMeepleType}
            />
          )}
          {recoveredMeeplesEvent?.recoveredMeeples.map((m) => {
            const meeple = cloneObject(m);
            meeple.recovered = false;
            // tile offset
            const t = tileData.filter(
              (t) => t.meeple?.playerId === meeple.playerId && t.meeple?.recovered && t?.meeple?.id === meeple.id
            )[0];
            if (!t || !t.meeple) return null;
            // ccson specific
            let left = t.x * tileWidth;
            let top = t.y * tileHeight;
            // add meeple offset
            const tileDef: TileDef = getTileDef(t.type, family);
            const nodes = tileDef.nodes;
            const meepleNode = nodes.find((node) => node.id === meeple.nodeId);
            const meepleOffset = calculateMeeplePos(meepleNode, t.rot, family) as CSSPos;
            console.log('recoveredMeepleOffset', t, left, top);
            left += meepleOffset.left as number;
            top += meepleOffset.top as number;
            const tileStyle = { width: 40, position: 'absolute' as const, left, top };
            return (
              <CSSTransition
                key={meeple.id}
                in={true}
                appear={true}
                timeout={3000}
                classNames="recover"
                unmountOnExit
                onEntered={() => {
                  setRecoveredMeeples(null);
                }}>
                <div style={tileStyle}>
                  <Meeple meeple={meeple} />
                </div>
              </CSSTransition>
            );
          })}
        </div>
      </PrismaZoom>
      {myPlayer && meeples && (
        <MeepleCont color={myPlayer.color} meeples={meeples} onMeepleTypeChange={handleMeepleTypeChange} />
      )}
      <div className={classes.rightBtnCont}>
        <Button onClick={handleZoomIn} small>
          <FaPlus />
        </Button>
        <Button onClick={handleZoomOut} small>
          <FaMinus />
        </Button>
        <Button onClick={handleRotate} small>
          <FiRotateCcw />
        </Button>
      </div>
      <TileAnchor zoom={zoom} dragEnabled={false}>
        {lastTile && active && !selectedPos && <TileItem family={family} active={true} tile={lastTile} />}
      </TileAnchor>
      <div className={classes.submitBtn}>
        <Button onClick={handleSubmit} disabled={!submitBtnEnabled}>
          Submit
        </Button>
      </div>
      <div className={classes.skipBtn}>
        <Button onClick={handleSkip} disabled={!submitBtnEnabled}>
          Skip
        </Button>
      </div>
    </div>
  );
};

export default TileMap;
