import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GameList from '@envclient/envcore/src/components/GameList';
import wsConn from '@envclient/envcore/src/service/WsConnection';
import classes from './GameCont.module.scss';
import { getUserInfo } from '@envclient/envcore/src/service/userService';
import AppHeader from '@envclient/envcore/src/components/AppHeader';
import InviteDialog from '@envclient/envcore/src/components/InviteDialog';
import Chat from '@envclient/envcore/src/components/Chat';
import store, { RootState, useAppSelector } from '@envclient/envcore/src/reducers/store';
import { InviteMessageDTO } from '@envclient/envcore/src/types/core';
import { setFirstConnect } from '@envclient/envcore/src/reducers/firstConnectReducer';
import { AppStatus } from '@envclient/envcore/src/reducers/appStatusReducer';
import Lobby from '@envclient/envcore/src/components/Lobby';
import { ClosableCompProps } from './types';
import { createGameActions } from '../reducers/createGameReducer';
import bell from '../assets/bell3.mp3';

const GameCont: FC<GameContProps> = ({ GameView, CreateGame, Guide }) => {
  const appStatus = useSelector((state: RootState) => state.appStatus);

  const invites = useSelector((state: RootState) => state.invites) as InviteMessageDTO[];
  const [inviteOpen, setInviteOpen] = useState<boolean>(false);
  const showCreate = useAppSelector<boolean>((state) => state.createGame);

  const dispatch = useDispatch();

  useEffect(() => {
    //load user then connect
    store.dispatch(setFirstConnect(false));
    getUserInfo().then(() => wsConn.connect());
  }, []);

  // invites
  useEffect(() => {
    if (invites?.length === 0) return;
    setInviteOpen(true);
    playSound();
  }, [invites]);

  const onShowCreate = () => {
    dispatch(createGameActions.showCreate());
  };

  const onHideCreate = () => {
    dispatch(createGameActions.hideCreate());
  };

  const playSound = () => {
    const audio = new Audio(bell);
    audio.play();
  };

  return (
    <div className={classes.mainCont}>
      <AppHeader />
      <Lobby />
      <GameList onShowCreate={onShowCreate} showCreate={showCreate} />
      {showCreate && <CreateGame onClose={() => onHideCreate()} />}
      <Chat roomId={0} showChat={true} isPrivate={false} />
      <Guide />
      {appStatus === AppStatus.GAME && <GameView />}
      {inviteOpen && <InviteDialog invite={invites[0]} onClose={() => setInviteOpen(false)} />}
    </div>
  );
};

interface GameContProps {
  GameView: FC;
  CreateGame: FC<ClosableCompProps>;
  Guide: FC;
}

export default GameCont;
