import kTileCount from '../config/koenigsburg/koenigsburg_tilecount.json';
import koenigsburgTileTypesJson from '../config/koenigsburg/koenigsburg_tilemap.json';

export interface NodeDef {
  id: number;
  type: string;
  x: number;
  y: number;
}

export interface KTileDef {
  type: number;
  count?: number;
  sides: string;
  nodes: Array<NodeDef>;
  rot?: number;
  rhombus?: boolean;
}

const koenigsburgTileTypes: Record<number, KTileDef> = koenigsburgTileTypesJson;

const getTileDef = (type: number): KTileDef => {
  return koenigsburgTileTypes[type];
};

export { kTileCount, getTileDef };
