import React from 'react';
import ReactDOM from 'react-dom';
import './stylesheets/sevencastles.theme.scss';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from './App';
import store, { history } from '@envclient/envcore/src/reducers/store';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept();
}

Sentry.init({
  dsn: 'https://8d947af9b8424fca82a14b41334f95d2@sentry.io/295263',
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
