import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import classes from './TileAnchor.module.scss';
import useDrag from '../../hooks/useDrag';

const initialTranslate = { x: 0, y: 0 };

interface TileAnchorProps {
  zoom: number;
  dragEnabled: boolean;
}

const TileAnchor = (props: PropsWithChildren<TileAnchorProps>) => {
  const { zoom, dragEnabled } = props;

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTranslate(initialTranslate);
  }, [props.children]);

  const [translate, setTranslate] = useState(initialTranslate);

  const handleDrag = (e: React.PointerEvent) => {
    if (!dragEnabled) return;
    setTranslate({
      x: translate.x + e.movementX,
      y: translate.y + e.movementY
    });
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useDrag(divRef, [translate], {
    onDrag: handleDrag
  });

  return (
    <div
      className={classes.anchor}
      ref={divRef}
      style={{
        transform: `translateX(${translate.x}px) translateY(${translate.y}px) scaleX(${zoom}) scaleY(${zoom})`
      }}>
      {props.children}
    </div>
  );
};

export default TileAnchor;
