import Modal from './Modal';
import { ClosableCompProps } from '@envclient/envcore/src/components/types';
import React, { FC } from 'react';
import classes from './BuyDialog.module.scss';
import Button from '@envclient/envcore/src/components/Button';

/**
 * Facebook redirect dialog.
 * @param param0
 * @returns
 */

const BuyDialogFacebookRedirect: FC<ClosableCompProps> = ({ onClose }) => {
  const handleClose = () => {
    onClose();
  };

  const handleBuy = () => {
    onClose();
    window.open('https://sevencastles.eu/game', '_blank');
  };

  return (
    <>
      <Modal>
        <div className={classes.mainCont}>
          <div className={classes.bg}></div>
          <div className={classes.panel}>
            <div className={classes.header}>
              <div className={classes.title}>Buy credits at &apos;sevencastles.eu&apos;</div>
            </div>
            <div className={classes.body}>
              <p className={classes.text}>
                Buying credits is currently only possible at sevencastles.eu. <p />
                <p>Click &apos;Continue&apos; to proceed.</p>
              </p>
            </div>
            <div className={classes.btnBar}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleBuy}>Continue to &apos;sevencastles.eu&apos;</Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BuyDialogFacebookRedirect;
