import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import gameReducer from './gameReducer';
import lobbyReducer from './lobbyReducer';
import appStatusReducer from './appStatusReducer';
import userReducer from './userReducer';
import gameListReducer from './gameListReducer';
import invitationReducer from './invitationReducer';
import firstConnectReducer from './firstConnectReducer';
import { History } from 'history';
import currentRoomReducer from './currentRoomReducer';
import createGameReducer from './createGameReducer';
import guideReducer from './guideReducer';

const rootReducerCreator = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    appStatus: appStatusReducer,
    currentRoom: currentRoomReducer,
    user: userReducer,
    gameList: gameListReducer,
    invites: invitationReducer,
    lobby: lobbyReducer,
    game: gameReducer,
    firstConnect: firstConnectReducer,
    createGame: createGameReducer,
    guide: guideReducer
  });

export default rootReducerCreator;
