import React, { FC, useEffect, useState } from 'react';
import classes from './MeepleCont.module.scss';
import Meeple from './Meeple';
import { Color, MeepleDTO, MeepleType } from '../../types/ccson_env';
import Button from '@envclient/envcore/src/components/Button';

const MeepleCont: FC<MeepleContProps> = ({ color, meeples, onMeepleTypeChange }) => {
  const [selectedType, setSelectedType] = useState<MeepleType>();

  useEffect(() => {
    let defaultSelection: MeepleType | undefined = undefined;
    Object.entries(meeples).map(([meepleType, meepleArr]) => {
      if (meepleArr.length && !defaultSelection) defaultSelection = meepleType as MeepleType;
    });
    if (!selectedType || meeples[selectedType]?.length == 0) {
      setSelectedType(defaultSelection);
      onMeepleTypeChange(defaultSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeples, selectedType]);

  if (!meeples) return null;

  const handleClick = (meepleType: MeepleType) => {
    setSelectedType(meepleType);
    onMeepleTypeChange(meepleType);
  };

  return (
    <div className={classes.meepleCont}>
      {Object.entries(meeples).map(([meepleType, meepleArr]) => {
        const meepleCount = meepleArr && meepleArr.length;

        const meeple: MeepleDTO = {
          id: -1,
          color: color,
          recovered: false,
          type: meepleType as MeepleType,
          value: 0,
          playerId: -1,
          nodeId: -1,
          score: -1
        };
        return (
          <Button
            key={meepleType}
            onClick={() => handleClick(meeple.type)}
            disabled={meepleCount === 0}
            toggled={meeple.type === selectedType}
            secondary
            small>
            <Meeple meeple={meeple} inMeepleCont={true} />
            <span className={classes.count}>x{meepleCount}</span>
          </Button>
        );
      })}
    </div>
  );
};

interface MeepleContProps {
  color: Color;
  meeples: { [key in MeepleType]?: MeepleDTO[] };
  onMeepleTypeChange: (type: MeepleType | undefined) => void;
}

export default MeepleCont;
