// this file only defines plain actions

const FIRST_CONNECT = 'FIRST_CONNECT';
const APP_STATUS = 'APP_STATUS';
const USER = 'USER';
const GAME_LIST = 'GAME_LIST';
const INVITE = 'INVITE';
const INVITE_CONSUME = 'INVITE_CONSUME';
const CREATE_GAME_DIALOG = 'CREATE_GAME_DIALOG';
const LOGIN_DIALOG = 'LOGIN_DIALOG';
const WS_SEND = 'WS_SEND';

export {
  APP_STATUS,
  USER,
  GAME_LIST,
  INVITE,
  INVITE_CONSUME,
  CREATE_GAME_DIALOG,
  LOGIN_DIALOG,
  WS_SEND,
  FIRST_CONNECT
};
