const TOKEN_NAME = 'scToken';

const saveToken = (token: string) => {
  localStorage.setItem(TOKEN_NAME, token);
};

const deleteToken = () => {
  localStorage.setItem(TOKEN_NAME, '');
};

const loadToken = () => {
  return localStorage.getItem(TOKEN_NAME);
};

export { saveToken, loadToken, deleteToken };
