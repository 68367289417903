import { useGoogleLogin } from 'react-use-googlelogin';
import { GoogleResponse } from '../types/frontendTypes';
/**
 Google signIn is deprecated - swotch to new Goolge Identity sERvices soon..
 https://developers.google.com/identity/gsi/web/guides/migration 
 *
 */
const useGoogle = () => {
  const googleAuth = useGoogleLogin({
    clientId: window.runtimeConfig.GOOGLE_CLIENT_ID
  });
  return googleAuth;
};

export const googleLogin = (): Promise<GoogleResponse> => {
  console.log('Google login');
  return new Promise((resolve, reject) => {
    window.plugins.googleplus.login(
      {
        webClientId: window.runtimeConfig.GOOGLE_CLIENT_ID
      },
      function (response) {
        console.log('Google success', response);
        resolve(response);
      },
      function (msg) {
        console.log('Google error', msg);
        reject(msg);
      }
    );
  });
};

export default useGoogle;
