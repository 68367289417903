import React, { FC } from 'react';
import classes from './KGameCanvas.module.scss';
import { GameCanvasProps } from '@envclient/envcore/src/components/GameView';
import { KEnvDTO } from '../../types/koenigsburg_env';
import KTileMap from './KTileMap';

/**
 * Kgsburg game comps: ktilemap
 */
const KoenigsburgGameCanvas: FC<GameCanvasProps> = ({ env, active, game, onSubmit }) => {
  const { tiles, currentActions, tilesLeft, lastTile } = env as KEnvDTO;
  const startBtnVisible = game.status === 'IDLE';

  return (
    <>
      {tiles && (
        <KTileMap
          tileData={tiles}
          currentActions={currentActions}
          active={active}
          lastTile={lastTile}
          onSubmit={onSubmit}
        />
      )}
      <div className={classes.tilesLeft}>{!startBtnVisible && `Tiles: ${tilesLeft}`}</div>
    </>
  );
};

export default KoenigsburgGameCanvas;
