import Modal from './Modal';
import { ClosableCompProps } from '@envclient/envcore/src/components/types';
import React, { FC, useState, useEffect } from 'react';
import classes from './BuyDialog.module.scss';
import Button from '@envclient/envcore/src/components/Button';
import { RootState, useAppSelector } from '../reducers/store';
import { UserDTO } from '../types/core';
import { OrderData, Product } from '../types/frontendTypes';
import { getUserInfo } from '../service/userService';
import { insertPayment } from '../service/paymentService';
import Alert from './Alert';
/**
 * Paypal buy dialog. Displays two Paypal buttons.
 */

const productIds = ['credit_10', 'credit_20', 'credit_25', 'credit_50', 'credit_75', 'credit_80', 'credit_100'];

const BuyDialogApp: FC<ClosableCompProps> = ({ onClose }) => {
  const myUser = useAppSelector((state: RootState) => state.user) as UserDTO;
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

  useEffect(() => {
    // load products
    window.inAppPurchase
      .getProducts(productIds)
      .then(function (products: Product[]) {
        console.log('Loaded products', products);
        setProducts(products);
        setSelectedProduct(products[0]);
      })
      .catch(function (err) {
        console.log('Product loading error:', err);
      });
  }, []);

  const handleClose = () => {
    getUserInfo().then(onClose);
  };

  const handleBuyResult = (success: boolean) => {
    if (success) setShowAlert(true);
    else setShowErrorAlert(true);
  };

  const handleChange = (productId: string) => {
    const p = products.find((p) => p.productId === productId) as Product;
    setSelectedProduct(p);
  };

  const handleSubmit = () => {
    if (!selectedProduct) return;

    window.inAppPurchase
      .buy(selectedProduct?.productId)
      .then(function (orderData: OrderData) {
        console.log('Buy result', orderData);
        return window.inAppPurchase
          .consume(orderData.productType, orderData.receipt, orderData.signature)
          .then(() => orderData);
      })
      .then(function (orderData) {
        console.log('Product consumed!', orderData);

        // insertPayment + receipt validation
        let payload;
        if (window.device.platform === 'Android') {
          payload = {
            platform: 'Android',
            userId: myUser.userId,
            credits: selectedProduct.productId.substr(7),
            itemId: selectedProduct.productId,
            paymentId: JSON.parse(orderData.receipt).orderId,
            receipt: orderData.receipt,
            signature: orderData.signature
            //appVersion: settings.version
          };
        } else {
          // IOS
          payload = {
            platform: 'IOS',
            userId: myUser.userId,
            credits: selectedProduct.productId.substr(7),
            itemId: selectedProduct.productId,
            paymentId: orderData.transactionId,
            receipt: orderData.receipt
            //appVersion: settings.version
          };
        }

        insertPayment(payload)
          .then((data) => {
            console.log('Insert payment success', data);
            handleBuyResult(true);
          })
          .catch((error) => {
            console.log('Insert payment error', error);
            handleBuyResult(false);
          });
      })
      .catch(function (err: unknown) {
        console.log('Buy error', err);
      });
  };

  return (
    <>
      {showAlert && (
        <Alert
          title="Successful purchase"
          message="Thank you for buying credits! Your credits will appear shortly - please reload the page!"
          onClose={() => {
            setShowAlert(false);
            handleClose();
          }}
        />
      )}
      {showErrorAlert && (
        <Alert
          title="Purchase error"
          message="Purchase failed!"
          onClose={() => {
            setShowErrorAlert(false);
            handleClose();
          }}
        />
      )}
      <Modal>
        <div className={classes.mainCont}>
          <div className={classes.bg}></div>
          <div className={classes.panel}>
            <div className={classes.header}>
              <div className={classes.title}>Buy credits</div>
            </div>
            <div className={classes.body}>
              <p className={classes.text}>Please select the amount of credits you wish to buy and click Continue.</p>
              <div className={classes.optionRow}>
                <label htmlFor="amount">Amount</label>
                {selectedProduct ? (
                  <select
                    name="amount"
                    id="amount"
                    value={selectedProduct.productId}
                    onChange={(e) => handleChange(e.target.value)}>
                    {products.map((p) => {
                      return (
                        <option key={p.productId} value={p.productId}>
                          {`${p.description} - $${p.price}`}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <span>{'Loading items - please wait...'}</span>
                )}
              </div>
              <div className={classes.optionRow}></div>
            </div>
            <div className={classes.btnBar}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSubmit} disabled={!selectedProduct}>
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BuyDialogApp;
