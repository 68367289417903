import { Reducer } from 'redux';
import { GameDTO } from '../types/core';
import { GAME_LIST } from './actions';

const initialState: GameDTO[] = [];

export const setGameList = (payload: GameDTO[]) => ({ type: GAME_LIST, payload });

const gameListReducer: Reducer<GameDTO[]> = (state = initialState, action) => {
  if (action.type === GAME_LIST) {
    return action.payload;
  }
  return state;
};

export default gameListReducer;
