import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './Lobby.module.scss';
import WsConnection from '@envclient/envcore/src/service/WsConnection';
import LobbyUserList from './LobbyUserList';
import { RootState } from '@envclient/envcore/src/reducers/store';
import { CreateMessageDTO, GameType, RoomDTO, UserDTO } from '@envclient/envcore/src/types/core';
import { createGameActions } from '../reducers/createGameReducer';
import { sleep } from '../utils/utils';

const Lobby: FC = () => {
  const lobbyUserList = useSelector((state: RootState) => state.lobby.userList);
  const currentRoom = useSelector((state: RootState) => state.currentRoom);
  const myUser = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const [inviteUser, setInviteUser] = useState<UserDTO>();
  const [waitingForAutoInvite, setWaitingForAutoInvite] = useState<boolean>(false);

  const handleInvite = (user: UserDTO) => {
    // already in game
    if (currentRoom?.roomId !== 0) {
      WsConnection.send({ type: 'INVITE', room: currentRoom as RoomDTO, user });
    } else {
      // show createGameDialog and save invite user
      dispatch(createGameActions.showCreate());
      setInviteUser(user);
    }
  };

  useEffect(
    function botJoinedGameInviteUser() {
      if (inviteUser && currentRoom?.roomId !== 0) {
        console.log('sendInvite', inviteUser);
        WsConnection.send({ type: 'INVITE', room: currentRoom as RoomDTO, user: inviteUser });
        setInviteUser(undefined);
        setWaitingForAutoInvite(false);
      }
    },
    [currentRoom, inviteUser]
  );

  useEffect(
    function botJoinedLobby() {
      if (myUser?.type !== 'bot') return;
      if (currentRoom?.roomId === 0 && !inviteUser && !waitingForAutoInvite) {
        // find a user in lobby and invite her to seamini
        const u = lobbyUserList.filter((u) => u.type === 'user' && u.userId !== myUser.userId)[0];
        if (!u) return;
        console.log('autoInvite1', currentRoom.roomId, myUser, lobbyUserList.length, inviteUser, waitingForAutoInvite);
        const gameType: GameType = 'seamini';
        const daysSinceFirstLogin = (Date.now() - new Date(u.firstLogin).getTime()) / (24 * 3600 * 1000);
        if (daysSinceFirstLogin > 10) {
          return;
        }

        // wait 30 secs
        setWaitingForAutoInvite(true);
        //console.log('autoInvite2');
        sleep(30000).then(() => {
          // create game
          const gameObj: CreateMessageDTO = {
            type: 'CREATE' as const,
            envType: 'ccson' as const,
            gameType: gameType,
            delay: 30 * 1000,
            maxPlayer: 4,
            locked: false
          };
          WsConnection.send(gameObj);
          // send invite
          sleep(3000).then(() => {
            //console.log('setInvite');
            setInviteUser(u);
          });
        });
      }
    },
    [currentRoom?.roomId, myUser, lobbyUserList, inviteUser, waitingForAutoInvite]
  );

  if (!myUser) return null;

  return (
    <div className={classes.mainCont}>
      <div className={classes.header}>
        <div className={classes.title}>Lobby</div>
      </div>
      <LobbyUserList userList={lobbyUserList} myUser={myUser} onInvite={handleInvite} />
    </div>
  );
};

export default Lobby;
