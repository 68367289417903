import React, { FC } from 'react';

import classes from './KTilePlaceholder.module.scss';
import { getContainerStyle, getTileStyle } from '../ccson/TileAsset';
import { TileBasedGameFamilyType } from '../../config/tileGameConfig';
import { ActionDTO } from '@envclient/envcore';
import { SelectPosActions } from '../ccson/TileMap';

const KTilePlaceholder: FC<KTilePlaceholderProps> = (props) => {
  const { x, y, actions, onClick, family } = props;

  const handleClick = () => {
    onClick({ selectedPos: { x, y }, posActions: actions });
  };

  const tileStyle = getTileStyle(x, y, family);
  const containerStyle = getContainerStyle({ x, y }, 0, family);

  const style = {
    left: tileStyle.left,
    top: tileStyle.top,
    width: containerStyle.width,
    height: containerStyle.height
  };

  return <div className={classes.tilePlaceholder} style={style} onClick={handleClick}></div>;
};

interface KTilePlaceholderProps {
  x: number;
  y: number;
  actions: ActionDTO[];
  onClick: (payload: SelectPosActions<ActionDTO>) => void;
  family: TileBasedGameFamilyType;
}

export default KTilePlaceholder;
