import React, { FC, useEffect, useState } from 'react';
import classes from './PlayerList.module.scss';
import { GameStatus, PlayerDTO } from '@envclient/envcore/src/types/core';
import { useInterval } from 'react-use';

const PlayerList: FC<PlayerListProps> = (props) => {
  const { players, actPlayer, delay, myPlayer, gameStatus, PlayerItem } = props;
  const [isRunning, setIsRunning] = useState(false);
  const [count, setCount] = useState(0);
  const maxTime = delay / 1000;
  const timeLeft = maxTime - count;
  const interval = 1000;

  useInterval(
    () => {
      setCount(count + 1);
    },
    isRunning ? interval : null
  );

  // restart timer when actPlayer changes
  // TODO restarts when otehr player reconnects -send message or timestamp from server instead
  useEffect(() => {
    if (gameStatus === 'PLAYING') {
      setCount(0);
      setIsRunning(true);
    } else {
      setIsRunning(false);
      setCount(0);
    }
  }, [actPlayer, myPlayer, gameStatus]);

  return (
    <div className={classes.playerCont}>
      {players.map((player) => (
        <PlayerItem key={player.id} player={player} timeLeft={timeLeft} actPlayer={actPlayer} gameStatus={gameStatus} />
      ))}
    </div>
  );
};
export interface PlayerListProps {
  actPlayer?: PlayerDTO;
  myPlayer?: PlayerDTO;
  players: PlayerDTO[];
  delay: number;
  gameStatus: GameStatus;
  PlayerItem: FC<PlayerItemProps>;
}

export interface PlayerItemProps {
  player: PlayerDTO;
  actPlayer?: PlayerDTO;
  timeLeft: number;
  gameStatus: GameStatus;
}

export default PlayerList;
