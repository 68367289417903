let deviceready = false;

document.addEventListener('deviceready', function () {
  console.log('deviceready');
  deviceready = true;

  /*
  navigator.splashscreen.hide();

  StatusBar.hide();

  if (device.platform === 'iOS') {
    //gcLoginBtn.show();
  } else if (device.platform == 'Android') {
    //googleLoginBtn.show();
  }
  */
});

//  init FB SDK - browser only
if (!window.runtimeConfig.IS_MOBILE) {
  (function (d, s, id) {
    let js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.async = true;
    js.src = '//connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');

  window.fbAsyncInit = function () {
    FB.init({
      appId: window.runtimeConfig.REACT_APP_FB_APP_ID,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v14.0',
      status: true, // check login status,
      cookie: false, // dont use cookie for session storage
      localStorage: false // dont use localStorage for session storage
    });
  };
}

function fbLogin() {
  if (window.runtimeConfig.IS_MOBILE && deviceready) {
    return fbLoginDevice();
  } else {
    return fbLoginBrowser();
  }
}

function fbLoginBrowser() {
  return new Promise((resolve, reject) => {
    console.log('fbLoginBrowser', FB);

    FB.getLoginStatus(function (response) {
      if (response.status === 'connected') {
        console.log('Logged in, details:', response.authResponse);
        const token = response.authResponse.accessToken;
        console.log('accessToken', token);
        resolve(token);
      } else {
        console.log('Not logged in');
        FB.login(function (response) {
          if (response.status === 'connected') {
            // contains the 'status' - bool, 'authResponse' - object with 'session_key', 'accessToken', 'expiresIn', 'userID'
            console.log('FB login response', response.authResponse.signedRequest);
            const token = response.authResponse.accessToken;
            resolve(token);
          } else {
            console.log('FB login failed!');
            reject('FB login failed!');
          }
        });
      }
    });
  });
}

function fbLoginDevice() {
  return new Promise((resolve, reject) => {
    console.log('fbLoginDevice', facebookConnectPlugin);

    facebookConnectPlugin.getLoginStatus(function (response) {
      if (response.status === 'connected') {
        console.log('Logged in, details:', response.authResponse);
        const token = response.authResponse.accessToken;
        console.log('accessToken', token);
        resolve(token);
      } else {
        console.log('Not logged in');
        facebookConnectPlugin.login(
          ['public_profile', 'email'],
          function (response) {
            if (response.status === 'connected') {
              // contains the 'status' - bool, 'authResponse' - object with 'session_key', 'accessToken', 'expiresIn', 'userID'
              console.log('FB login response', response.authResponse.signedRequest);
              const token = response.authResponse.accessToken;
              resolve(token);
            }
          },
          function (error) {
            console.log('FB login failed!', error);
            reject('FB login failed! ' + error);
          }
        );
      }
    }, true);
  });
}

function fbBuy(amount) {
  console.log('buy', amount);

  return new Promise(function (resolve, reject) {
    function buyCallback(data) {
      console.log('buyCallback', data);

      if (data.status == 'initiated') {
        //
      } else if (data.status == 'completed') {
        resolve();
      } else {
        reject();
      }
    }
    FB.ui(
      {
        method: 'pay',
        action: 'purchaseitem',
        product: 'https://sevencastles.eu/og/ccson_credits.html',
        quantity: amount // optional, defaults to 1
        //quantity_min: 10,                // optional, defaults to quantity
        //quantity_max: 1000,              // optional, defaults to quantity
      },
      buyCallback
    );
  });
  return buyResult;
}

export { fbLogin, fbBuy };
