import React, { FC, ReactNode, useState } from 'react';
import wsConn from '@envclient/envcore/src/service/WsConnection';
import classes from './/CreateGame.module.scss';
import { CSSTransition } from 'react-transition-group';
import Button from '@envclient/envcore/src/components/Button';
import { TileBasedGameType } from '../config/tileGameConfig';
import classNames from 'classnames';
import Modal from '@envclient/envcore/src/components/Modal';
import Alert from '@envclient/envcore/src/components/Alert';
import createTileItem from '../envs/ccson/createTileItem';
import { CreateMessageDTO } from '@envclient/envcore/src/types/core';
import { ClosableCompProps } from '@envclient/envcore/src/components/types';
import { TileGameConfig } from '../types/ccson_env';
import GameFamilies from './guide/GameFamilies';
import { GameFamily } from '../config/gameFamilyConfig';
import { FaArrowLeft, FaTimes } from 'react-icons/fa';
import { getUserInfo } from '@envclient/envcore/src/service/userService';
import { isSupported } from '@envclient/envcore/src/utils/game';

const CreateGame: FC<ClosableCompProps> = ({ onClose }) => {
  // seamini game is selected by default
  const [selectedType, setSelectedType] = useState<TileGameConfig>();
  const [delay, setDelay] = useState<number>(30);
  const [maxPlayer, setMaxPlayer] = useState<number>(2);
  const [locked, setLocked] = useState<boolean>(true);

  const [selectedFamily, setSelectedFamily] = useState<GameFamily>();
  const [games, setGames] = useState<TileGameConfig[]>();

  const [showCreditAlert, setShowCreditAlert] = useState<boolean>(false);

  async function handleCreateGame() {
    if (!selectedFamily || !selectedType) return;

    // check credits and alert if not enough
    const user = await getUserInfo();
    if (user && user?.credits < selectedType.credits) {
      setShowCreditAlert(true);
      return;
    }

    const gameObj: CreateMessageDTO = {
      type: 'CREATE' as const,
      envType: selectedFamily?.envType,
      gameType: selectedType?.type as TileBasedGameType,
      delay: delay * 1000,
      maxPlayer: maxPlayer,
      locked: locked
    };
    wsConn.send(gameObj);
    onClose();
  }

  const handleClose = () => {
    onClose();
  };

  const handleSelectFamily = (gf: GameFamily) => {
    setSelectedFamily(gf);
    setSelectedType(gf.games[0]);
    setGames(gf.games.filter((gc) => isSupported(gc, window.appConfig.version)));
  };

  const handleSelect = (gc: TileGameConfig) => {
    setSelectedType(gc);
  };

  const back = () => {
    setSelectedType(undefined);
    setSelectedFamily(undefined);
  };

  const renderTitle = (): ReactNode => {
    if (selectedFamily && selectedType) {
      return (
        <>
          <Button secondary onClick={back}>
            <FaArrowLeft />
          </Button>
          {selectedFamily.title} / {selectedType.label}
        </>
      );
    } else if (selectedFamily) {
      return (
        <>
          <Button secondary onClick={back}>
            <FaArrowLeft />
          </Button>
          {selectedFamily.title} /
        </>
      );
    }
    return 'Create game:';
  };

  return (
    <>
      {showCreditAlert && (
        <Alert
          title="Credit check"
          message="You dont have enough credits for this game!"
          onClose={() => setShowCreditAlert(false)}
        />
      )}
      <Modal>
        <CSSTransition in={true} timeout={250} classNames="fade">
          <div className={classes.mainCont}>
            <div className={classes.bg}></div>
            <div className={classes.panel}>
              <div className={classes.header}>
                <div className={classes.title}> {renderTitle()}</div>
                <Button secondary onClick={handleClose}>
                  <FaTimes />
                </Button>
              </div>
              {!selectedFamily ? (
                <GameFamilies onSelectFamily={handleSelectFamily} />
              ) : (
                <>
                  <div className={classes.body}>
                    <div className={classes.subtitle}>Game type</div>
                    <div className={classes.gameList}>
                      {games &&
                        games.map((gc: TileGameConfig) => {
                          const gameTypeClasses = classNames(classes.gameType, {
                            [classes.selected]: selectedType === gc
                          });
                          return (
                            <div key={gc.type as string} className={gameTypeClasses} onClick={() => handleSelect(gc)}>
                              <div className={classes.gameTitle}>{gc.label}</div>
                              <div className={classes.tileCont}>
                                {createTileItem(gc.thumbnailTile, selectedFamily.type)}
                              </div>

                              <div className={classes.detail}>
                                <div className={classes.credits}>{gc.credits} credits</div>
                                <div className={classes.credits}>{gc.points} points</div>
                                <div>{gc.tiles} tiles</div>
                                <div>{gc.meepleCount.meeple} meeples</div>
                                {gc.meepleCount.giant && <div>{gc.meepleCount.giant} giants</div>}
                                {gc.meepleCount.ship && <div>{gc.meepleCount.ship} ships </div>}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className={classes.subtitle}>Game options</div>
                    <div className={classes.gameOptions}>
                      <div className={classes.optionRow}>
                        <label htmlFor="delay">Game speed</label>
                        <select
                          name="delay"
                          id="delay"
                          value={delay}
                          onChange={(e) => setDelay(Number(e.target.value))}>
                          <option value={30}>30 sec</option>
                          <option value={60}>60 sec</option>
                          <option value={90}>90 sec</option>
                        </select>
                      </div>
                      <div className={classes.optionRow}>
                        <label htmlFor="max">Max players</label>
                        <select
                          name="max"
                          id="max"
                          value={maxPlayer}
                          onChange={(e) => setMaxPlayer(Number(e.target.value))}>
                          <option value={1}>1 player</option>
                          <option value={2}>2 players</option>
                          <option value={3}>3 players</option>
                          <option value={4}>4 players</option>
                        </select>
                      </div>
                      <div className={classes.optionRow}>
                        <label htmlFor="locked">Locked</label>
                        <input
                          id="locked"
                          type="checkbox"
                          checked={locked}
                          onChange={(e) => setLocked(Boolean(e.target.checked))}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.btnBar}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleCreateGame}>Submit</Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </CSSTransition>
      </Modal>
    </>
  );
};

export default CreateGame;
