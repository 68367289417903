import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import freeze from 'redux-freeze';
import { createBrowserHistory } from 'history';
import createHashHistory from 'history/createHashHistory';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import rootReducerCreator from './rootReducer';
import thunk from 'redux-thunk';
//import config from './configuration';

//export const history = createBrowserHistory({ basename: config.runtimeConfig.FRONTEND_PREFIX });
export const history = window.runtimeConfig.IS_MOBILE
  ? createHashHistory({ basename: window.runtimeConfig.FRONTEND_PREFIX })
  : createBrowserHistory({ basename: window.runtimeConfig.FRONTEND_PREFIX });

const middleware = [thunk, routerMiddleware(history)];

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'development') {
  middleware.push(freeze);
}

const rootReducer = rootReducerCreator(history);

const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
  devTools: process.env.NODE_ENV === 'development' || true
});

// infer state type from rootReducer return type
export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
