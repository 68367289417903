import React, { FC } from 'react';
import { ccsonTileCount } from '../../config/tileMapConfig';
import GuideTileItem from './GuideTileItem';
import classes from './Guide.module.scss';
import castleImg from '../../assets/guide/castle.png';
import castleRiver from '../../assets/guide/castles_river.png';
import castleBridge from '../../assets/guide/castle_bridge.png';
const CastlesGuide: FC = () => {
  return (
    <>
      <h3>Seven Castles</h3>

      <p>Seven Castles is a Carcassonne spin-off game. In this game you build castles enclosed by walls and rivers.</p>

      <h3>Scoring</h3>

      <img src={castleImg} className={classes.guideImg} />
      <img src={castleRiver} className={classes.guideImg} />
      <img src={castleBridge} className={classes.guideImg} />
      <p>
        <span>Castle walls</span> - 1 points / tile when completed or at the end of game. Castle walls can end at
        rivers.
      </p>

      <p>
        <span>Fields</span> - 1 point / tile when closed by castle walls or rivers. They score 0 if not completed until
        end of game. There musn&apos;t be missign tiles (wholes) in the field.
      </p>

      <p>
        <span>Bridges</span> - connect two fields. If your field has a bridge you also need to close the connected field
        to complete it.
      </p>

      <p>
        <span>Rivers</span> - 1 point / tile when completed. If not completed they still score 1 point/tile at the end.
        Rivers are handled as a whole - they are not segmented like roads.
      </p>

      <p>
        <span>Cloisters</span> - 1 point for each neighbouring tile, 9 points when completed.
      </p>

      <h3>Castles mini tiles</h3>
      <div className={classes.tileList}>
        {ccsonTileCount.castlesmini.map((tc) => (
          <GuideTileItem key={tc} tc={tc} family={'castle'} />
        ))}
      </div>

      <h3>Castles tiles</h3>
      <div className={classes.tileList}>
        {ccsonTileCount.castles.map((tc) => (
          <GuideTileItem key={tc} tc={tc} family={'castle'} />
        ))}
      </div>

      <h3>Castles Exp1 tiles</h3>
      <div className={classes.tileList}>
        {ccsonTileCount.castlesplus.map((tc) => (
          <GuideTileItem key={tc} tc={tc} family={'castle'} />
        ))}
      </div>
    </>
  );
};

export default CastlesGuide;
