import React, { FC } from 'react';

import classes from './TilePlaceholder.module.scss';
import { getContainerStyle, getTileStyle } from './TileAsset';
import { TileBasedGameFamilyType } from '../../config/tileGameConfig';
import { ActionDTO } from '@envclient/envcore';
import { SelectPosActions } from './TileMap';

const TilePlaceholder: FC<TilePlaceholderProps> = (props) => {
  const { x, y, actions, onClick, family } = props;

  const handleClick = () => {
    onClick({ selectedPos: { x, y }, posActions: actions });
  };

  const tileStyle = getTileStyle(x, y, family);
  const containerStyle = getContainerStyle({ x, y }, 0, family);

  const style = {
    left: tileStyle.left,
    top: tileStyle.top,
    width: containerStyle.width,
    height: containerStyle.height
  };

  return <div className={classes.tilePlaceholder} style={style} onClick={handleClick}></div>;
};

interface TilePlaceholderProps {
  x: number;
  y: number;
  actions: ActionDTO[];
  onClick: (payload: SelectPosActions<ActionDTO>) => void;
  family: TileBasedGameFamilyType;
}

export default TilePlaceholder;
