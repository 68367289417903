import Modal from './Modal';
import { ClosableCompProps } from '@envclient/envcore/src/components/types';
import React, { FC, useState } from 'react';
import classes from './BuyDialog.module.scss';
import Button from '@envclient/envcore/src/components/Button';
import Alert from './Alert';
import { convertPoints, getUserInfo } from '../service/userService';
import { useAppSelector } from '../reducers/store';

/**
 * Convert points dialog.
 */

const CONVERSION_RATE = 25;

const ConvertPointsDialog: FC<ClosableCompProps> = ({ onClose }) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const user = useAppSelector((state) => state.user);

  if (!user) return null;

  const conversionFunc = (points: number) => Math.floor(points / CONVERSION_RATE);

  const pointsToConvert = conversionFunc(user.points);
  const bonusCredits = pointsToConvert * CONVERSION_RATE;

  const lastPurchaseFormatted = new Date(user.lastPurchase).toDateString();
  const daysSinceLastPurchase = (Date.now() - new Date(user.lastPurchase).getTime()) / 1000 / 60 / 60 / 24;
  const hasRecentPurchase = daysSinceLastPurchase < 30;
  const hasPoints = user.points >= 25;

  const handleClose = () => {
    getUserInfo().then(onClose);
  };

  const handleConvertResult = (success: boolean) => {
    if (success) setShowAlert(true);
    else setShowErrorAlert(true);
  };

  const handleConvert = () => {
    if (!user) return;
    convertPoints()
      .then(() => {
        handleConvertResult(true);
      })
      .catch(() => {
        handleConvertResult(false);
      });
  };

  return (
    <>
      {showAlert && (
        <Alert
          title="Successful conversion"
          message="Your credits will be available soon!"
          onClose={() => {
            setShowAlert(false);
            handleClose();
          }}
        />
      )}
      {showErrorAlert && (
        <Alert
          title="Conversion error"
          message="Conversion failed!"
          onClose={() => {
            setShowErrorAlert(false);
            handleClose();
          }}
        />
      )}
      <Modal>
        <div className={classes.mainCont}>
          <div className={classes.bg}></div>
          <div className={classes.panel}>
            <div className={classes.header}>
              <div className={classes.title}>Convert points to credits</div>
            </div>
            <div className={classes.body}>
              <p className={classes.text}>
                You can convert your points to credits only if you have made at least one purchase in the last 30 days.
              </p>
              <p className={classes.text}>After the conversion your points will decrease with the converted amount. </p>
              <p className={classes.text}>The conversion rate is 25 points = 1 credit. </p>
              <div className={classes.conversionRow}>
                <div className={classes.highlight}>Last purchase</div>
                <div>{hasRecentPurchase ? lastPurchaseFormatted : 'You need a purchase not older then 30 days.'}</div>
              </div>
              <div className={classes.conversionRow}>
                <div className={classes.highlight}>Amount</div>
                <div>
                  {!hasPoints
                    ? 'You need at least 25 points for a conversion.'
                    : hasRecentPurchase
                    ? `Converting ${pointsToConvert} credits to ${bonusCredits} points.`
                    : `You have ${user.points} points.`}
                </div>
              </div>
            </div>
            <div className={classes.btnBar}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button disabled={!hasPoints || !hasRecentPurchase} onClick={handleConvert}>
                Convert points
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConvertPointsDialog;
