import React, { FC, useState } from 'react';
import classes from './LoginDialog.module.scss';
import Button from '@envclient/envcore/src/components/Button';
import { fbLogin } from '@envclient/envcore/src/login/fbLogin';
import { loginWithApple, loginWithFb, loginWithGoogle } from '@envclient/envcore/src/service/userService';
import CircleLoader from './CircleLoader';
import useGoogle, { googleLogin } from '../login/googleLogin';
import WsConnection from '../service/WsConnection';
import { appleLoginMobile } from '../login/appleLogin';

const LoginDialog: FC = () => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
  const googleAuth = useGoogle();

  const handleFbLogin = async () => {
    try {
      setLoaderVisible(true);
      setErrorMessage(undefined);
      const token = await fbLogin();
      const response = await loginWithFb(token);
      // handle unmounted case
      setLoaderVisible(false);
      console.log('login resp', response);
      WsConnection.connect();
    } catch (err) {
      console.error('Facebook login failed', err);
      // handle unmounted case
      setErrorMessage('Login with Facebook failed.');
      setLoaderVisible(false);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      setLoaderVisible(true);
      setErrorMessage(undefined);
      let token;
      if (!window.runtimeConfig.IS_MOBILE) {
        const googleUser = await googleAuth.signIn();
        token = googleUser?.tokenId;
      } else {
        const googleUser = await googleLogin();
        token = googleUser?.idToken;
      }
      if (!token) return;
      const response = await loginWithGoogle(token);
      setLoaderVisible(false);
      console.log('login resp', response);
      WsConnection.connect();
    } catch (err) {
      console.error('Google login failed', err);
      setErrorMessage('Login with Google failed.');
      setLoaderVisible(false);
    }
  };

  const handleAppleLogin = async () => {
    try {
      setLoaderVisible(true);
      setErrorMessage(undefined);
      let token;
      let userName;
      if (window.runtimeConfig.IS_MOBILE) {
        const appleResponse = await appleLoginMobile();
        token = appleResponse?.identityToken;
        userName = appleResponse.fullName?.givenName || appleResponse.fullName?.familyName || '';
      } else {
        // TODO web based apple signin with oauth redirect
        return;
      }
      if (!token) return;
      const response = await loginWithApple(token, userName);
      setLoaderVisible(false);
      console.log('login resp', response);
      WsConnection.connect();
    } catch (err) {
      console.error('Apple login failed', err);
      setErrorMessage('Login with Apple failed.');
      setLoaderVisible(false);
    }
  };

  return (
    <div className={classes.mainCont}>
      <div className={classes.bg}></div>
      <div className={classes.panel}>
        <div className={classes.header}>
          <div className={classes.title}>Login</div>
        </div>
        <div className={classes.body}>
          {loaderVisible && <CircleLoader size={20} />}
          {errorMessage && <div className={classes.error}>{errorMessage}</div>}
        </div>
        <div className={classes.btnBar}>
          <Button onClick={handleFbLogin}>Facebook Login</Button>
          <Button onClick={handleGoogleLogin}>Google Login</Button>
          {window.runtimeConfig.IS_MOBILE && <Button onClick={handleAppleLogin}>Apple Login</Button>}
        </div>
      </div>
    </div>
  );
};

export default LoginDialog;
