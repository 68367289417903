import { Pos } from '@envclient/envcore/src/types/frontendTypes';
import { KFamilyTypeDTO } from '../../types/koenigsburg_env';
import { TileBasedGameFamilyType } from '../../config/tileGameConfig';

const hexaTileWidth = 130 * 2;
const hexaTileHeight = 150 * 2;
const hexaTileDX = 65 * 2;
const hexaTileDY = 113 * 2;

const rhombusTileWidth = 65 * 2;
const rhombusTileHeight = 113 * 2;

function koenigsburgTileCoordsByIndex(i: number) {
  const hexaMapping: Record<number, Pos> = {
    // hexa coords!!!
    49: { x: 0, y: 0 },
    50: { x: 1, y: 0 },
    51: { x: 0, y: 1 },
    52: { x: 1, y: 1 },
    53: { x: 0, y: 2 },
    54: { x: 1, y: 2 },
    55: { x: 0, y: 3 },
    56: { x: 1, y: 3 },
    57: { x: 0, y: 4 },
    58: { x: 1, y: 4 },
    59: { x: 0, y: 5 },
    60: { x: 1, y: 5 },
    61: { x: 0, y: 6 },
    62: { x: 1, y: 6 },
    69: { x: 0, y: 7 },
    70: { x: 1, y: 7 },
    71: { x: 0, y: 8 },
    72: { x: 1, y: 8 },
    73: { x: 0, y: 9 },
    74: { x: 1, y: 9 },
    75: { x: 0, y: 10 },
    76: { x: 1, y: 10 },
    77: { x: 0, y: 11 }
  };

  if (i < 100) {
    return {
      x: hexaMapping[i].x * hexaTileWidth + hexaMapping[i].y * hexaTileDX,
      y: hexaMapping[i].y * hexaTileDY
    };
  }

  const rhombusMapping: Record<number, Pos> = {
    100: { x: 0, y: 0 }
  };

  return {
    // TODO ????
    x: rhombusMapping[i].x * rhombusTileWidth,
    y: rhombusMapping[i].y * rhombusTileHeight
  };
}

function isKoenigsburgFamily(family: TileBasedGameFamilyType): boolean {
  return (family as KFamilyTypeDTO) == 'koenigsburg';
}

const getTileStyle = (x: number, y: number, rhombus: boolean) => {
  return rhombus ? getRhombusTileStyle(x, y) : getHexaTileStyle(x, y);
};

const getHexaTileStyle = (x: number, y: number) => {
  const left = x * hexaTileWidth + y * hexaTileDX + 'px';
  const top = y * hexaTileDY + 'px';
  return { left, top };
};

const getRhombusTileStyle = (x: number, y: number, rot?: number) => {
  // TODO rhombus position varies within hexagon depending on rotation
  const left = x * hexaTileWidth + y * hexaTileDX + 'px';
  const top = y * hexaTileDY + 'px';
  return { left, top };
};

const getTileCoords = (type: number) => {
  return koenigsburgTileCoordsByIndex(type);
};

const getHexaContainerStyle = (coordinates: Pos, rotation: number) => {
  return {
    width: hexaTileWidth + 'px',
    height: hexaTileHeight + 'px',
    backgroundPosition: `-${coordinates.x}px -${coordinates.y}px`,
    transformOrigin: hexaTileWidth / 2 + 'px ' + hexaTileHeight / 2 + 'px',
    transform: 'rotate(' + rotation + 'deg)'
  };
};

const getContainerStyle = (coordinates: Pos, rotation: number) => {
  return getHexaContainerStyle(coordinates, rotation);
};

const getTileWidth = (rhombus: boolean) => {
  return rhombus ? rhombusTileWidth : hexaTileWidth;
};

const getTileHeight = (rhombus: boolean) => {
  return rhombus ? rhombusTileHeight : hexaTileHeight;
};

export { isKoenigsburgFamily, getTileStyle, getTileCoords, getContainerStyle, getTileWidth, getTileHeight };
