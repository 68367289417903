import { RouterState } from 'connected-react-router';
import { Store } from 'redux';
import { RootState } from '../reducers/store';

export const observeStore = (
  store: Store,
  select: (state: RootState) => RouterState<unknown>,
  onChange: (state: RouterState<unknown>, nextState: RouterState<unknown>) => void
) => {
  let currentState: RouterState<unknown>;

  function handleChange() {
    const nextState = select(store.getState());
    if (nextState !== currentState) {
      const oldState = currentState;
      currentState = nextState;
      onChange(currentState, oldState);
    }
  }

  const unsubscribe = store.subscribe(handleChange);
  //handleChange
  return unsubscribe;
};
